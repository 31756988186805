import React, { useMemo, useCallback } from "react";
import { useStoreon } from "storeon/react";
import { useDropzone } from "react-dropzone";
import shortid from "shortid";

import { withStyles } from "@material-ui/core/styles";
import { FirebaseStorage } from "services/firebase";
import { addImageToAssetPack } from "services/dbService";
import { uploaderStyles } from "services/theme";

const styles = (theme) => ({
  uploadArea: {
    position: "fixed",
    top: 64,
    left: 0,
    height: 100,
    width: "100%",
    zIndex: 3,
  },
});

export default withStyles(styles)(({ classes, selectedCategory, refreshUserList }) => {
  const { dispatch, auth } = useStoreon("auth");

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (!acceptedFiles || acceptedFiles.length === 0) {
        return false;
      }

      dispatch("ui/setLoading", true);
      FirebaseStorage.init(auth.user.uid);
      const added = [];
      const start = async () => {
        await Promise.all(
          acceptedFiles.map(async (file) => {
            const id = shortid.generate();
            const now = new Date();
            const filename = `${id}_${file.name}`;

            const uploadRef = FirebaseStorage.images.child(filename);
            const snapshot = await uploadRef.put(file);
            const downloadURL = await snapshot.ref.getDownloadURL();

            const newImage = {
              id,
              category: selectedCategory,
              url: downloadURL,
              filename,
              tags: [],
              title: file.name,
              createdAt: now,
            };
            added.push(newImage);
            addImageToAssetPack(newImage);
          })
        );
        // Fetch all images again
        refreshUserList({ added });
      };

      start();
    },
    [auth.user.uid, dispatch, selectedCategory, refreshUserList]
  );
  const acceptText = `image/jpeg, image/png${selectedCategory === "audio" ? ", audio/mp3, audio/mpeg" : ""}`;
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: acceptText,
    onDrop: onDrop,
  });

  const style = useMemo(
    () => ({
      ...uploaderStyles.baseStyle,
      ...(isDragActive ? uploaderStyles.activeStyle : {}),
      ...(isDragAccept ? uploaderStyles.acceptStyle : {}),
      ...(isDragReject ? uploaderStyles.rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <section className={classes.uploadArea}>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
    </section>
  );
});
