import React, { useState } from "react";
import { useStoreon } from "storeon/react";

import { withStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import AddCircle from "@material-ui/icons/AddCircle";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Close from "@material-ui/icons/Close";

import { getAssetUrl } from "services/utilities";
import { bgColors } from "services/theme";

const styles = (theme) => ({
  ...bgColors,
  nested: {
    padding: "7px 0 7px 20px",
    fontSize: 10,
    backgroundColor: "#282b2a",
  },
  searchWrapper: {
    backgroundColor: "#282b2a",
  },
  searchFilter: {
    marginLeft: 15,
    width: "100%",
  },
  filterClear: {
    padding: 2,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  elementAvatar: {
    width: 35,
    height: 35,
  },
  badge: {
    border: "1px solid #191d1c",
  },
  listItemTitle: {
    textTransform: "capitalize",
  },
});
// {isOpen.players ? <ExpandLess color="primary" /> : <ExpandMore color="inherit" />}

export default withStyles(styles)(({ classes, Icon, elementType }) => {
  /* eslint-disable no-unused-vars */
  const { dispatch, currentStory } = useStoreon("currentStory");
  const [filter, setFilter] = useState("");
  const [isOpen, setIsOpen] = useState();
  /* eslint-enable no-unused-vars */

  const elementAction = (id) => {
    switch (elementType) {
      case "locations":
        dispatch("router/setParams", { storypage: "location", locationID: id });
        break;
      case "events":
        dispatch("router/setParams", { storypage: "event", eventID: id });
        break;
      default:
        dispatch("router/openModal", { modal: "ModalElements", id: id, type: elementType });
        break;
    }
  };

  return (
    <React.Fragment>
      <ListItem button onClick={() => setIsOpen(!isOpen)}>
        <Badge
          badgeContent={currentStory.story[elementType] ? currentStory.story[elementType].length : 0}
          color="primary"
          classes={{ badge: classes.badge }}
        >
          <Avatar className={classes[`${[elementType]}Bg`]}>
            <Icon />
          </Avatar>
        </Badge>
        <ListItemText inset primary={elementType} className={classes.listItemTitle} />
        <ListItemSecondaryAction>
          <IconButton
            aria-label="Add New"
            onClick={() => dispatch("router/openModal", { modal: "ModalElements", id: 0, type: elementType, data: {} })}
          >
            <AddCircle />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem className={classes.searchWrapper}>
            <Input
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              className={classes.searchFilter}
              autoComplete={`filter-${elementType}`}
              autoFocus
              endAdornment={
                <InputAdornment position="end">
                  <IconButton className={classes.filterClear} onClick={() => setFilter("")}>
                    <Close />
                  </IconButton>
                </InputAdornment>
              }
            />
          </ListItem>
          {currentStory.story[elementType] &&
            currentStory.story[elementType]
              .filter((e) => e.name.toUpperCase().indexOf(filter.toUpperCase()) > -1)
              .map((element) => (
                <ListItem key={element.id} button className={classes.nested} onClick={() => elementAction(element.id)}>
                  <Avatar
                    className={`${classes.elementAvatar} ${classes[`${elementType}Border`]} ${
                      classes[`${elementType}Bg`]
                    }`}
                    src={getAssetUrl(element.asset)}
                  >
                    {getAssetUrl(element.asset) ? "" : elementType.slice(0, 1).toUpperCase()}
                  </Avatar>
                  <ListItemText inset primary={element.name} />
                </ListItem>
              ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
});
