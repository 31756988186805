import shortid from "shortid";

const RouterStore = (store) => {
  store.on("@init", () => ({
    router: {
      page: "storiesList",
      title: "Dashboard",
      id: null,
      params: {},
      modalOpen: null,
      modalID: null,
      modalOptions: null,
      dirty: false,
    },
  }));

  store.on("router/routeTo", ({ router }, payload) => {
    // dirty = payload.params ? payload.params.dirty : false;
    if (router.dirty) {
      if (!window.confirm("You have unsaved changes. Are you sure you want to leave?")) {
        return { router: { ...router } };
      }
    }
    const ret = {
      ...router,
      id: payload.id || null,
      params: payload.params || {},
      page: payload.page,
      title: payload.title || "",
      dirty: false,
    };
    return { router: ret };
  });

  store.on("router/setParams", ({ router }, payload) => {
    // dirty = action.payload.dirty;
    if (router.dirty) {
      if (!window.confirm("You have unsaved changes. Are you sure you want to leave?")) {
        return { router: { ...router } };
      }
    }
    const ret = { ...router, dirty: false, params: payload || {} };
    return { router: ret };
  });

  store.on("router/setDirty", ({ router }, payload) => {
    const ret = { ...router, dirty: payload };
    return { router: ret };
  });

  store.on("router/closeModal", ({ router }) => {
    const ret = {
      ...router,
      modalOpen: null,
      modalID: null,
      modalOptions: null,
    };
    return { router: ret };
  });

  store.on("router/openModal", ({ router }, payload) => {
    let data = null;
    let options = null;

    switch (payload.type) {
      case "locations":
        data = payload.data;
        options = {
          elementType: "locations",
          fields: {
            ...data,
            name: data.name || "LocationName",
            description: data.description || "",
            asset: data.asset || {
              pack: "user",
              category: "locations",
              file: "",
            },
            music: data.music || { category: "audio", file: null, pack: "user" },
            coordinates: data.coordinates || { x: 0, y: 0 },
            blocks: data.blocks || [
              {
                id: shortid.generate(),
                buttonTxt: "Enter",
                introTxt: "",
                mainTxt: "",
                characterID: null,
                conditions: [],
                clues: [],
                objectives: [],
                objectivesTxt: "",
                completes: [],
                completesTxt: "",
              },
            ],
          },
        };
        break;
      case "events":
        data = payload.data;
        options = {
          elementType: "events",
          fields: {
            ...data,
            name: data.name || "Event Name",
            asset: data.asset || {
              pack: "user",
              type: "locations",
              file: "",
            },
            music: data.music || { category: "audio", file: null, pack: "user" },
            blocks: data.blocks || [
              {
                id: shortid.generate(),
                buttonTxt: "Continue",
                introTxt: "",
                mainTxt: "",
                characterID: null,
                clues: [],
                objectives: [],
                objectivesTxt: "",
                completes: [],
                completesTxt: "",
              },
            ],
            trigger: {
              type: "turn", // turn, elementsEach, elementsTotal
              elements: [],
              elementsTxt: "",
              turnNumber: 1,
            },
            choices: data.choices || [
              {
                buttonTxt: "Choice1",
                blocks: data.blocks || [
                  {
                    id: shortid.generate(),
                    buttonTxt: "Continue",
                    introTxt: "",
                    mainTxt: "",
                    characterID: null,
                    clues: [],
                    objectives: [],
                    objectivesTxt: "",
                    completes: [],
                    completesTxt: "",
                  },
                ],
                isEnd: false,
                canUndo: false,
              },
            ],
            hasTriggered: false,
          },
        };
        break;
      case "players":
        options = {
          elementType: "players",
          fields: {
            name: "",
            description: "",
            preIntro: [{ text: "" }],
            postIntro: [{ text: "" }],
            asset: {
              file: "",
              pack: "user",
              category: "characters",
            },
            mentions: [],
            text: "",
            required: false,
          },
        };
        break;
      case "topics":
        options = {
          elementType: "topics",
          fields: {
            name: "",
            description: "",
            asset: {
              file: "",
              pack: "user",
              category: "topics",
            },
          },
        };
        break;
      case "characters":
        options = {
          elementType: "characters",
          fields: {
            name: "",
            description: "",
            asset: {
              file: "",
              pack: "user",
              category: "characters",
            },
          },
        };
        break;
      case "objectives":
        options = {
          elementType: "objectives",
          fields: {
            name: "",
            description: "",
            legend: "",
            parent: 0,
            belongsTo: {
              type: "players",
              id: 0,
            },
          },
        };
        break;
      default:
        options = {
          elementType: "topics",
          fields: {
            name: "",
            description: "",
            asset: {
              file: "",
              pack: "user",
              category: "topics",
            },
          },
        };
    }
    // end element choose switch

    const ret = {
      ...router,
      modalOpen: payload.modal,
      modalID: payload.id,
      modalOptions: { ...options },
    };
    return { router: ret };
  });
};

export default RouterStore;
