const isJson = (item) => {
  let it = typeof item !== "string" ? JSON.stringify(item) : item;

  try {
    it = JSON.parse(it);
  } catch (e) {
    return false;
  }

  if (typeof it === "object" && it !== null) {
    return true;
  }

  return false;
};

const camelCase = (str) => {
  const st = str
    .replace(/-/g, " ") // convert all hyphens to spaces
    .replace(/\s[a-z]/g, str.toLowerCase()) // convert first char of each word to UPPERCASE
    .replace(/\s+/g, "") // remove spaces
    .replace(/^[A-Z]/g, str.toUpperCase()); // convert first char to lowercase
  return st;
};

const getAssetUrl = (asset) => {
  if (asset && asset.pack && asset.category && asset.file) {
    return asset.file;
  }
  if (asset && asset.pack && asset.type && asset.file) {
    return `/asset_packs/${asset.pack}/${asset.type}/${asset.file}`;
  }
  return "";
};

const findElementMentions = (story, element) => {
  let count = 0;
  let where = {
    intro: 0,
    conditions: 0,
    clues: 0,
    completes: 0,
    objectives: 0,
    eventClues: 0,
    eventCompletes: 0,
    eventObjectives: 0,
    eventChoiceClues: 0,
    eventChoiceCompletes: 0,
    eventChoiceObjectives: 0,
    eventTrigger: 0,
    players: 0,
  };

  where.intro = story.introElements.elements.reduce((tot, cur) => (cur.id === element.id ? tot + 1 : tot), 0);
  count += where.intro;

  story.players.forEach((pl) => {
    where.players += pl.mentions.reduce((tot, cur) => (cur.id === element.id ? tot + 1 : tot), 0);
  });
  story.locations.forEach((loc) => {
    loc.blocks.forEach((block) => {
      block.conditions.forEach(
        (item) => (where.conditions += item.elements.reduce((tot, cur) => (cur.id === element.id ? tot + 1 : tot), 0))
      );
      block.clues.forEach(
        (item) => (where.clues += item.elements.reduce((tot, cur) => (cur.id === element.id ? tot + 1 : tot), 0))
      );
      where.completes += block.completes.reduce((tot, cur) => (cur.id === element.id ? tot + 1 : tot), 0);
      where.objectives += block.objectives.reduce((tot, cur) => (cur.id === element.id ? tot + 1 : tot), 0);
    });
  });
  story.events.forEach((event) => {
    event.blocks.forEach((block) => {
      block.clues.forEach(
        (item) => (where.eventClues += item.elements.reduce((tot, cur) => (cur.id === element.id ? tot + 1 : tot), 0))
      );
      where.eventCompletes += block.completes.reduce((tot, cur) => (cur.id === element.id ? tot + 1 : tot), 0);
      where.eventObjectives += block.objectives.reduce((tot, cur) => (cur.id === element.id ? tot + 1 : tot), 0);
    });
    where.eventTrigger += event.trigger.elements.reduce((tot, cur) => (cur.id === element.id ? tot + 1 : tot), 0);
    event.choices.forEach((choice) => {
      choice.blocks.forEach((block) => {
        block.clues.forEach(
          (item) =>
            (where.eventChoiceClues += item.elements.reduce((tot, cur) => (cur.id === element.id ? tot + 1 : tot), 0))
        );
        where.eventChoiceCompletes += block.completes.reduce((tot, cur) => (cur.id === element.id ? tot + 1 : tot), 0);
        where.eventChoiceObjectives += block.objectives.reduce(
          (tot, cur) => (cur.id === element.id ? tot + 1 : tot),
          0
        );
      });
    });
  });
  count += where.conditions;
  count += where.clues;
  count += where.completes;
  count += where.objectives;
  count += where.eventClues;
  count += where.eventCompletes;
  count += where.eventObjectives;
  count += where.eventTrigger;
  count += where.eventChoiceClues;
  count += where.eventChoiceCompletes;
  count += where.eventChoiceObjectives;

  for (const [key, value] of Object.entries(where)) {
    if (value === 0) {
      delete where[key];
    }
  }

  return { count, where };
};

const updateAllMentions = (story, oldName, element) => {
  // @TODO: also change the elements[].display from oldName to new
  story.introElements.text = story.introElements.text
    .split(`[${oldName}](${element.id})`)
    .join(`[${element.name}](${element.id})`);

  story.locations.forEach((loc) => {
    loc.blocks.forEach((block) => {
      block.conditions.forEach(
        (cond) => (cond.text = cond.text.split(`[${oldName}](${element.id})`).join(`[${element.name}](${element.id})`))
      );
      block.clues.forEach(
        (clue) => (clue.text = clue.text.split(`[${oldName}](${element.id})`).join(`[${element.name}](${element.id})`))
      );
      block.objectivesTxt = block.objectivesTxt
        .split(`[${oldName}](${element.id})`)
        .join(`[${element.name}](${element.id})`);
      block.completesTxt = block.completesTxt
        .split(`[${oldName}](${element.id})`)
        .join(`[${element.name}](${element.id})`);
    });
  });

  story.events.forEach((event) => {
    event.blocks.forEach((block) => {
      block.clues.forEach(
        (clue) => (clue.text = clue.text.split(`[${oldName}](${element.id})`).join(`[${element.name}](${element.id})`))
      );
      block.objectivesTxt = block.objectivesTxt
        .split(`[${oldName}](${element.id})`)
        .join(`[${element.name}](${element.id})`);
      block.completesTxt = block.completesTxt
        .split(`[${oldName}](${element.id})`)
        .join(`[${element.name}](${element.id})`);
    });

    event.trigger.elementsTxt = event.trigger.elementsTxt
      .split(`[${oldName}](${element.id})`)
      .join(`[${element.name}](${element.id})`);

    event.choices.forEach((choice) => {
      choice.blocks.forEach((block) => {
        block.clues.forEach(
          (clue) =>
            (clue.text = clue.text.split(`[${oldName}](${element.id})`).join(`[${element.name}](${element.id})`))
        );
        block.objectivesTxt = block.objectivesTxt
          .split(`[${oldName}](${element.id})`)
          .join(`[${element.name}](${element.id})`);
        block.completesTxt = block.completesTxt
          .split(`[${oldName}](${element.id})`)
          .join(`[${element.name}](${element.id})`);
      });
    });
  });
  return story;
};

const getTypeFromId = (story, el_id) => {
  if (story.characters.find((el) => el.id === el_id)) {
    return "characters";
  }
  if (story.events.find((el) => el.id === el_id)) {
    return "events";
  }
  if (story.locations.find((el) => el.id === el_id)) {
    return "locations";
  }
  if (story.objectives.find((el) => el.id === el_id)) {
    return "objectives";
  }
  if (story.players.find((el) => el.id === el_id)) {
    return "players";
  }
  if (story.topics.find((el) => el.id === el_id)) {
    return "topics";
  }
  return null;
};

const getPlainTextFromMention = (mentionText) =>
  mentionText.replace(/(.?)\[(.*?)\]\((.*?)\)/g, (match, symbol, title, id) => title);

const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

const getDateFromAll = (dateAny) => {
  if (typeof dateAny === "string" || dateAny instanceof String) {
    return new Date(dateAny);
  } else if (dateAny instanceof Date) {
    return dateAny;
  } else if (dateAny.seconds) {
    return dateAny.toDate();
  } else {
    return dateAny;
  }
};

export {
  isJson,
  camelCase,
  getAssetUrl,
  updateAllMentions,
  getTypeFromId,
  getPlainTextFromMention,
  asyncForEach,
  findElementMentions,
  getDateFromAll,
};
