import { getPuzzles } from "services/dbService";

const AuthStore = (store) => {
  store.on("@init", () => ({
    auth: {
      logged: null,
      user: {},
      puzzles: [],
    },
  }));

  store.on("auth/setLogged", ({ auth }, logged) => {
    const puzzles = getPuzzles();
    return { auth: { ...auth, logged, puzzles } };
  });

  store.on("auth/setUser", ({ auth }, user) => {
    return { auth: { ...auth, user } };
  });
};

export default AuthStore;
