import React from "react";
import { useStoreon } from "storeon/react";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { AuthSignin } from "services/dbService";
import { useInputValue } from "services/hooks";

const styles = (theme) => ({
  layout: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  auth_link: {
    marginTop: theme.spacing(3),
  },
});

export default withStyles(styles)(({ classes, setShowRegister }) => {
  /* eslint-disable no-unused-vars */
  const { ...email } = useInputValue("");
  const { ...password } = useInputValue("");

  const { dispatch, ui, error, auth } = useStoreon("ui", "error", "auth");
  /* eslint-enable no-unused-vars */

  const SubmitLogin = (e) => {
    e.preventDefault();
    dispatch("ui/setLoading", true);
    AuthSignin({ email: email.value, password: password.value })
      .then(async (usr) => {
        window.location.reload();
        // TODO: causes error if we change auth state for state in unmounted component: fix as it is better to not refresh page.
      })
      .catch((error) => {
        dispatch("ui/setLoading", false);
        dispatch("error/setError", { visible: true, text: error.message });
      });
  };

  return (
    <main className={classes.layout}>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} onSubmit={SubmitLogin}>
          <FormControl margin="normal" required fullWidth>
            <InputLabel shrink={true} htmlFor="email">
              Email Address
            </InputLabel>
            <Input {...email} id="email" name="email" autoComplete="email" autoFocus />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel shrink={true} htmlFor="password">
              Password
            </InputLabel>
            <Input {...password} name="password" type="password" id="password" autoComplete="current-password" />
          </FormControl>
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            Sign in
          </Button>
        </form>
        {/*<Typography component="a" href="#" onClick={() => setShowRegister(true)} className={classes.auth_link}>
          Register
  </Typography>*/}
      </Paper>
    </main>
  );
});
