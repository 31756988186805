import React, { useState, useEffect } from "react";

import { useStoreon } from "storeon/react";

import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import MediaAsset from "modules/Main/_components/MediaAsset";

import { getAssetUrl } from "services/utilities";
import { storyUpdate, storyDelete, createNewStoryVersion } from "services/dbService";

const styles = {
  card: {
    maxWidth: "100%",
    width: 400,
    textAlign: "center",
    margin: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  media: {
    height: 250,
  },
  explainer: {
    marginTop: 5,
    marginBottom: 25,
    fontSize: "1em",
    fontStyle: "italic",
  },
};

export default withStyles(styles)(({ classes, story }) => {
  /* eslint-disable no-unused-vars */
  const { dispatch, ui, router, error, stories } = useStoreon("ui", "router", "error", "stories");

  const [isOpen, setIsOpen] = useState({
    deleteConfirm: false,
    storyInfo: false,
    copyConfirm: false,
  });
  const [st, setSt] = useState(null);
  /* eslint-enable no-unused-vars */

  useEffect(() => {
    setSt({
      ...story,
      name: story ? story.name : "Create New Story",
      description: story ? story.description : "",
      difficulty: story ? story.difficulty : "",
      duration: story ? story.duration : "",
      image: story && story.cover ? getAssetUrl(story.cover) : "",
      music: story && story.music ? story.music : { category: "audio", file: null, pack: "user" },
    });
  }, [isOpen.storyInfo, story]);

  const onCardClick = () => {
    if (story) {
      dispatch("router/routeTo", {
        page: "story",
        title: story.name,
        id: story.id,
        params: { storypage: "dashboard", locationID: null },
      });
    } else {
      dispatch("router/routeTo", {
        page: "createStory",
        title: "Create a new Story",
      });
    }
  };

  const deleteStoryClick = () => {
    dispatch("ui/setLoading", true);
    storyDelete(story.id)
      .then((deleted) => {
        dispatch(
          "stories/setStories",
          stories.stories.filter((s) => s.id !== story.id)
        );
        dispatch("ui/setLoading", false);
      })
      .catch((error) => {
        dispatch("ui/setLoading", false);
        dispatch("error/setError", { visible: true, text: error.message });
      });
  };

  const updateStoryClick = () => {
    dispatch("ui/setLoading", true);
    storyUpdate(st.id, {
      name: st.name,
      description: st.description,
      difficulty: st.difficulty,
      duration: st.duration,
      map: st.map,
      cover: st.cover,
      music: st.music,
    })
      .then((updated) => {
        // f8nd and replace js tree
        dispatch(
          "stories/setStories",
          stories.stories.map((s) => (s.id === st.id ? { ...s, ...st } : s))
        );
        dispatch("ui/setLoading", false);
        setIsOpen({ ...isOpen, storyInfo: false });
      })
      .catch((error) => {
        dispatch("ui/setLoading", false);
        dispatch("error/setError", { visible: true, text: error.message });
      });
    // update Story Js local
  };

  const changeVersion = (toStoryId) => {
    dispatch("ui/setLoading", false);
    dispatch("router/routeTo", {
      page: "story",
      title: st && st.name,
      id: toStoryId,
      params: { storypage: "dashboard", locationID: null },
    });
  };
  const addVersion = async (storyID) => {
    dispatch("ui/setLoading", true);
    const newID = await createNewStoryVersion(storyID);
    changeVersion(newID);
  };

  return (
    <React.Fragment>
      <Card className={classes.card}>
        <CardActionArea onClick={onCardClick} style={{ flexGrow: 1 }}>
          {st && st.image && <CardMedia className={classes.media} image={st.image} title={st.name} />}
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {st && st.name} {st && st.version && `(v${st.version.number})`}
            </Typography>
            <Typography component="div">{st && st.description}</Typography>
          </CardContent>
        </CardActionArea>
        {st && (
          <CardActions>
            <Button size="small" color="default" onClick={() => setIsOpen({ ...isOpen, storyInfo: true })}>
              Info
            </Button>
            {story && (
              <Button size="small" color="default" onClick={() => setIsOpen({ ...isOpen, copyConfirm: true })}>
                Copy to new Story
              </Button>
            )}
            <Button size="small" color="primary" onClick={() => setIsOpen({ ...isOpen, deleteConfirm: true })}>
              Delete
            </Button>
          </CardActions>
        )}
      </Card>

      <Dialog open={isOpen.deleteConfirm} onClose={() => setIsOpen({ ...isOpen, deleteConfirm: false })}>
        <DialogTitle>{"Are you sure you want to DELETE this Story?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ALL Story elements will be permanently deleted. Action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setIsOpen({ ...isOpen, deleteConfirm: false })} color="secondary">
            Cancel
          </Button>
          <Button variant="contained" onClick={deleteStoryClick} color="primary">
            DELETE
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isOpen.copyConfirm} onClose={() => setIsOpen({ ...isOpen, copyConfirm: false })}>
        <DialogTitle>{"Do you want to create a new copy of this Story?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>This can take some time to complete.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setIsOpen({ ...isOpen, copyConfirm: false })} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={() => addVersion(st && st.id)} color="secondary">
            DUPLICATE
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isOpen.storyInfo} onClose={() => setIsOpen({ ...isOpen, storyInfo: false })}>
        <DialogTitle>{st && st.name}</DialogTitle>
        <DialogContent>
          {st && (
            <TextField
              InputLabelProps={{ shrink: true }}
              type="text"
              margin="normal"
              fullWidth
              autoFocus
              label="Title"
              onChange={(e) => setSt({ ...st, name: e.target.value })}
              value={st.name}
            />
          )}
          {st && (
            <TextField
              InputLabelProps={{ shrink: true }}
              type="text"
              margin="normal"
              fullWidth
              autoFocus
              label="Description"
              onChange={(e) => setSt({ ...st, description: e.target.value })}
              value={st.description}
            />
          )}
          {st && (
            <TextField
              InputLabelProps={{ shrink: true }}
              type="text"
              margin="normal"
              fullWidth
              autoFocus
              label="Difficulty"
              onChange={(e) => setSt({ ...st, difficulty: e.target.value })}
              value={st.difficulty}
            />
          )}
          {st && (
            <TextField
              InputLabelProps={{ shrink: true }}
              type="text"
              margin="normal"
              fullWidth
              autoFocus
              label="Duration"
              onChange={(e) => setSt({ ...st, duration: e.target.value })}
              value={st.duration}
            />
          )}

          <Typography component="p" className={classes.explainer}>
            Main audio track. Used in game Lobby and Map (Location Select) {st && st.music && st.music.category}
          </Typography>
          {st && st.music && (
            <MediaAsset
              assetObject={st.music}
              hideSelects={true}
              halfImage={true}
              color="elements"
              assetChanged={(e) => setSt({ ...st, music: e })}
            />
          )}

          <Typography component="p" className={classes.explainer}>
            Story Map
          </Typography>
          {st && st.map && (
            <MediaAsset
              assetObject={st.map}
              hideSelects={true}
              halfImage={true}
              color="elements"
              assetChanged={(e) => setSt({ ...st, map: e })}
            />
          )}

          <Typography component="p" className={classes.explainer}>
            Story Cover Image
          </Typography>
          {st && st.cover && (
            <MediaAsset
              assetObject={st.cover}
              hideSelects={true}
              halfImage={true}
              color="elements"
              assetChanged={(e) => setSt({ ...st, cover: e })}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setIsOpen({ ...isOpen, storyInfo: false })} color="default">
            Close
          </Button>
          <Button variant="contained" onClick={updateStoryClick} color="secondary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
});
