Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require("babel-runtime/helpers/extends");

var _extends3 = _interopRequireDefault(_extends2);

var _slicedToArray2 = require("babel-runtime/helpers/slicedToArray");

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _classCallCheck2 = require("babel-runtime/helpers/classCallCheck");

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require("babel-runtime/helpers/createClass");

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require("babel-runtime/helpers/possibleConstructorReturn");

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require("babel-runtime/helpers/inherits");

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

var PrismaZoom = (function(_PureComponent) {
  (0, _inherits3.default)(PrismaZoom, _PureComponent);

  function PrismaZoom(props) {
    (0, _classCallCheck3.default)(this, PrismaZoom);

    // Last cursor position
    var _this = (0, _possibleConstructorReturn3.default)(this, (PrismaZoom.__proto__ || Object.getPrototypeOf(PrismaZoom)).call(this, props));

    _this.getNewPosition = function(x, y, zoom) {
      var _ref = [_this.state.zoom, _this.state.posX, _this.state.posY],
        prevZoom = _ref[0],
        prevPosX = _ref[1],
        prevPosY = _ref[2];

      if (zoom === 1) {
        return [0, 0];
      }

      if (zoom > prevZoom) {
        // Get container coordinates
        var rect = _this.refs.layout.getBoundingClientRect();

        // Retrieve rectangle dimensions and mouse position
        var centerX = rect.width / 2,
          centerY = rect.height / 2;
        var relativeX = x - rect.left,
          relativeY = y - rect.top;

        // If we are zooming down, we must try to center to mouse position

        var absX = (centerX - relativeX) / prevZoom,
          absY = (centerY - relativeY) / prevZoom;

        var ratio = zoom - prevZoom;
        return [prevPosX + absX * ratio, prevPosY + absY * ratio];
      } else {
        // If we are zooming down, we shall re-center the element
        return [(prevPosX * (zoom - 1)) / (prevZoom - 1), (prevPosY * (zoom - 1)) / (prevZoom - 1)];
      }
    };

    _this.getLimitedShift = function(shift, minLimit, maxLimit, minElement, maxElement) {
      if (shift > 0) {
        if (minElement > minLimit) {
          // Forbid move if we are moving to left or top while we are already out minimum boudaries
          return 0;
        } else if (minElement + shift > minLimit) {
          // Lower the shift if we are going out boundaries
          return minLimit - minElement;
        }
      } else if (shift < 0) {
        if (maxElement < maxLimit) {
          // Forbid move if we are moving to right or bottom while we are already out maximum boudaries
          return 0;
        } else if (maxElement + shift < maxLimit) {
          // Lower the shift if we are going out boundaries
          return maxLimit - maxElement;
        }
      }

      return shift;
    };

    _this.getCursor = function(canMoveOnX, canMoveOnY) {
      if (canMoveOnX && canMoveOnY) {
        return "move";
      } else if (canMoveOnX) {
        return "ew-resize";
      } else if (canMoveOnY) {
        return "ns-resize";
      } else {
        return "auto";
      }
    };

    _this.fullZoomInOnPosition = function(x, y) {
      var maxZoom = _this.props.maxZoom;

      var zoom = maxZoom;

      var _this$getNewPosition = _this.getNewPosition(x, y, zoom),
        _this$getNewPosition2 = (0, _slicedToArray3.default)(_this$getNewPosition, 2),
        posX = _this$getNewPosition2[0],
        posY = _this$getNewPosition2[1];

      _this.setState({ zoom: zoom, posX: posX, posY: posY, transitionDuration: _this.props.animDuration });
    };

    _this.move = function(shiftX, shiftY) {
      var transitionDuration = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
      var _this$props = _this.props,
        leftBoundary = _this$props.leftBoundary,
        rightBoundary = _this$props.rightBoundary,
        topBoundary = _this$props.topBoundary,
        bottomBoundary = _this$props.bottomBoundary;
      var _this$state = _this.state,
        posX = _this$state.posX,
        posY = _this$state.posY;

      // Get container and container's parent coordinates

      var rect = _this.refs.layout.getBoundingClientRect();
      var parentRect = _this.refs.layout.parentNode.getBoundingClientRect();

      // Get horizontal limits using specified horizontal boundaries
      var leftLimit = leftBoundary,
        rightLimit = document.body.clientWidth - rightBoundary;
      var isLarger =
          // Check if the element is larger than its container
          rect.width > rightLimit - leftLimit,
        isOutLeftBoundary =
          // Check if the element is out its container left boundary
          shiftX > 0 && rect.left - parentRect.left < 0,
        isOutRightBoundary =
          // Check if the element is out its container right boundary
          shiftX < 0 && rect.right - parentRect.right > 0;

      var canMoveOnX = isLarger || isOutLeftBoundary || isOutRightBoundary;
      if (canMoveOnX) {
        posX += _this.getLimitedShift(shiftX, leftLimit, rightLimit, rect.left, rect.right);
      }

      // Get vertical limits using specified vertical boundaries
      var topLimit = topBoundary,
        bottomLimit = document.body.clientHeight - bottomBoundary;
      var isHigher =
          // Check if the element is higher than its container
          rect.height > bottomLimit - topLimit,
        isOutTopBoundary =
          // Check if the element is out its container top boundary
          shiftY > 0 && rect.top - parentRect.top < 0,
        isOutBottomBoundary =
          // Check if the element is out its container bottom boundary
          shiftY < 0 && rect.bottom - parentRect.bottom > 0;

      var canMoveOnY = isHigher || isOutTopBoundary || isOutBottomBoundary;
      if (canMoveOnY) {
        posY += _this.getLimitedShift(shiftY, topLimit, bottomLimit, rect.top, rect.bottom);
      }

      var cursor = _this.getCursor(canMoveOnX, canMoveOnY);

      _this.setState({ posX: posX, posY: posY, cursor: cursor, transitionDuration: transitionDuration });
    };

    _this.startDeceleration = function(lastShiftOnX, lastShiftOnY) {
      var startTimestamp = null;

      var move = function move(timestamp) {
        if (startTimestamp === null) {
          startTimestamp = timestamp;
        }
        var progress = timestamp - startTimestamp;

        // Calculates the ratio to apply on the move (used to create a non-linear deceleration)
        var ratio = (_this.props.decelerationDuration - progress) / _this.props.decelerationDuration;

        var shiftX = lastShiftOnX * ratio,
          shiftY = lastShiftOnY * ratio;

        // Continue animation only if time has not expired and if there is still some movement (more than 1 pixel on one axis)

        if (progress < _this.props.decelerationDuration && Math.max(Math.abs(shiftX), Math.abs(shiftY)) > 1) {
          _this.move(shiftX, shiftY, 0);
          _this.lastRequestAnimationId = requestAnimationFrame(move);
        } else {
          _this.lastRequestAnimationId = null;
        }
      };

      _this.lastRequestAnimationId = requestAnimationFrame(move);
    };

    _this.handleMouseWheel = function(event) {
      //event.preventDefault();
      var _this$props2 = _this.props,
        minZoom = _this$props2.minZoom,
        maxZoom = _this$props2.maxZoom,
        scrollVelocity = _this$props2.scrollVelocity;
      var _this$state2 = _this.state,
        zoom = _this$state2.zoom,
        posX = _this$state2.posX,
        posY = _this$state2.posY;

      // Keep the previous zoom value

      var prevZoom = zoom;

      // Determine if we are increasing or decreasing the zoom
      var increaseZoom = event.deltaY < 0;

      // Set the new zoom value
      if (increaseZoom) {
        zoom = zoom + scrollVelocity < maxZoom ? zoom + scrollVelocity : maxZoom;
      } else {
        zoom = zoom - scrollVelocity > minZoom ? zoom - scrollVelocity : minZoom;
      }

      if (zoom !== prevZoom) {
        if (zoom !== minZoom) {
          var _this$getNewPosition3 = _this.getNewPosition(event.pageX, event.pageY, zoom);

          var _this$getNewPosition4 = (0, _slicedToArray3.default)(_this$getNewPosition3, 2);

          posX = _this$getNewPosition4[0];
          posY = _this$getNewPosition4[1];
        } else {
          var _ref2 = [_this.constructor.defaultState.posX, _this.constructor.defaultState.posY];
          // Reset to original position

          posX = _ref2[0];
          posY = _ref2[1];
        }
      }

      _this.setState({ zoom: zoom, posX: posX, posY: posY, transitionDuration: 0.05 });
      return false;
    };

    _this.handleDoubleClick = function(event) {
      event.preventDefault();

      if (_this.state.zoom === _this.props.minZoom) {
        _this.fullZoomInOnPosition(event.pageX, event.pageY);
      } else {
        _this.reset();
      }
    };

    _this.handleMouseStart = function(event) {
      event.preventDefault();

      if (_this.lastRequestAnimationId) {
        cancelAnimationFrame(_this.lastRequestAnimationId);
      }

      _this.lastCursor = { posX: event.pageX, posY: event.pageY };
    };

    _this.handleMouseMove = function(event) {
      event.preventDefault();

      if (!_this.lastCursor) {
        return;
      }
      if (_this.props.disabled) {
        return;
      }

      var _ref3 = [event.pageX, event.pageY],
        posX = _ref3[0],
        posY = _ref3[1];

      var shiftX = posX - _this.lastCursor.posX;
      var shiftY = posY - _this.lastCursor.posY;

      _this.move(shiftX, shiftY, 0);
      _this.lastCursor = { posX: posX, posY: posY };
      _this.lastShift = { x: shiftX, y: shiftY };
    };

    _this.handleMouseStop = function(event) {
      event.preventDefault();

      if (_this.lastShift) {
        // Use the last shift to make a decelerating movement effect
        _this.startDeceleration(_this.lastShift.x, _this.lastShift.y);
        _this.lastShift = null;
      }

      _this.lastCursor = null;
      _this.setState({ cursor: "auto" });
    };

    _this.handleTouchStart = function(event) {
      event.preventDefault();

      if (_this.lastRequestAnimationId) {
        cancelAnimationFrame(_this.lastRequestAnimationId);
      }

      var _ref4 = [event.touches[0].pageX, event.touches[0].pageY],
        posX = _ref4[0],
        posY = _ref4[1];

      if (event.touches.length === 1) {
        // Check if it is a double tap
        var touchTime = new Date().getTime();
        if (touchTime - _this.lastTouchTime < _this.props.doubleTouchMaxDelay && touchTime - _this.lastDoubleTapTime > _this.props.doubleTouchMaxDelay) {
          if (_this.state.zoom === _this.props.minZoom) {
            _this.fullZoomInOnPosition(posX, posY);
          } else {
            _this.reset();
          }
          _this.lastDoubleTapTime = touchTime;
        }

        _this.lastTouchTime = touchTime;
      }

      _this.lastTouch = { posX: posX, posY: posY };
    };

    _this.handleTouchMove = function(event) {
      event.preventDefault();

      var _this$props3 = _this.props,
        maxZoom = _this$props3.maxZoom,
        minZoom = _this$props3.minZoom;
      var zoom = _this.state.zoom;

      if (!_this.lastTouch) {
        return;
      }

      if (event.touches.length === 1) {
        var _ref5 = [event.touches[0].pageX, event.touches[0].pageY],
          posX = _ref5[0],
          posY = _ref5[1];
        // If we detect only one point, we shall just move the element

        var shiftX = posX - _this.lastTouch.posX;
        var shiftY = posY - _this.lastTouch.posY;

        _this.move(shiftX, shiftY);
        _this.lastShift = {
          x: shiftX,
          y: shiftY

          // Save data for the next move
        };
        _this.lastTouch = { posX: posX, posY: posY };
        _this.lastTouchDistance = null;
      } else if (event.touches.length > 1) {
        // If we detect two points, we shall zoom up or down
        var _ref6 = [event.touches[0].pageX, event.touches[0].pageY],
          pos1X = _ref6[0],
          pos1Y = _ref6[1];
        var _ref7 = [event.touches[1].pageX, event.touches[1].pageY],
          pos2X = _ref7[0],
          pos2Y = _ref7[1];

        var distance = Math.sqrt(Math.pow(pos2X - pos1X, 2) + Math.pow(pos2Y - pos1Y, 2));

        if (_this.lastTouchDistance && distance && distance !== _this.lastTouchDistance) {
          zoom += (distance - _this.lastTouchDistance) / 100;
          if (zoom > maxZoom) {
            zoom = maxZoom;
          } else if (zoom < minZoom) {
            zoom = minZoom;
          }

          // Change position using the center point between the two fingers
          var centerX = (pos1X + pos2X) / 2,
            centerY = (pos1Y + pos2Y) / 2;

          var _this$getNewPosition5 = _this.getNewPosition(centerX, centerY, zoom),
            _this$getNewPosition6 = (0, _slicedToArray3.default)(_this$getNewPosition5, 2),
            _posX = _this$getNewPosition6[0],
            _posY = _this$getNewPosition6[1];

          _this.setState({ zoom: zoom, posX: _posX, posY: _posY, transitionDuration: 0 });
        }

        // Save data for the next move
        _this.lastTouch = { posX: pos1X, posY: pos1Y };
        _this.lastTouchDistance = distance;
      }
    };

    _this.handleTouchStop = function() {
      if (_this.lastShift) {
        // Use the last shift to make a decelerating movement effect
        _this.startDeceleration(_this.lastShift.x, _this.lastShift.y);
        _this.lastShift = null;
      }

      _this.lastTouch = null;
      _this.lastTouchDistance = null;
    };

    _this.zoomIn = function(value) {
      var maxZoom = _this.props.maxZoom;
      var _this$state3 = _this.state,
        zoom = _this$state3.zoom,
        posX = _this$state3.posX,
        posY = _this$state3.posY;

      var prevZoom = zoom;

      zoom = zoom + value < maxZoom ? zoom + value : maxZoom;

      if (zoom !== prevZoom) {
        posX = (posX * (zoom - 1)) / (prevZoom > 1 ? prevZoom - 1 : prevZoom);
        posY = (posY * (zoom - 1)) / (prevZoom > 1 ? prevZoom - 1 : prevZoom);
      }

      _this.setState({ zoom: zoom, posX: posX, posY: posY, transitionDuration: _this.props.animDuration });
    };

    _this.zoomOut = function(value) {
      var minZoom = _this.props.minZoom;
      var _this$state4 = _this.state,
        zoom = _this$state4.zoom,
        posX = _this$state4.posX,
        posY = _this$state4.posY;

      var prevZoom = zoom;

      zoom = zoom - value > minZoom ? zoom - value : minZoom;

      if (zoom !== prevZoom) {
        posX = (posX * (zoom - 1)) / (prevZoom - 1);
        posY = (posY * (zoom - 1)) / (prevZoom - 1);
      }

      _this.setState({ zoom: zoom, posX: posX, posY: posY, transitionDuration: _this.props.animDuration });
    };

    _this.zoomToZone = function(relX, relY, relWidth, relHeight) {
      var _this$props4 = _this.props,
        maxZoom = _this$props4.maxZoom,
        leftBoundary = _this$props4.leftBoundary,
        rightBoundary = _this$props4.rightBoundary,
        topBoundary = _this$props4.topBoundary,
        bottomBoundary = _this$props4.bottomBoundary;
      var _this$state5 = _this.state,
        zoom = _this$state5.zoom,
        posX = _this$state5.posX,
        posY = _this$state5.posY;

      var prevZoom = zoom;

      // Calculate zoom factor to scale the zone
      var optimalZoomX = (document.body.clientWidth - leftBoundary - rightBoundary) / relWidth;
      var optimalZoomY = (document.body.clientHeight - topBoundary - bottomBoundary) / relHeight;
      zoom = Math.min(optimalZoomX, optimalZoomY, maxZoom);

      // Calculate new position to center the zone
      var rect = _this.refs.layout.getBoundingClientRect();
      var centerX = rect.width / prevZoom / 2,
        centerY = rect.height / prevZoom / 2;
      var zoneCenterX = relX + relWidth / 2,
        zoneCenterY = relY + relHeight / 2;

      posX = (centerX - zoneCenterX) * zoom;
      posY = (centerY - zoneCenterY) * zoom;

      _this.setState({ zoom: zoom, posX: posX, posY: posY, transitionDuration: _this.props.animDuration });
    };

    _this.reset = function() {
      _this.setState(
        (0, _extends3.default)({}, _this.constructor.defaultState, {
          transitionDuration: _this.props.animDuration
        })
      );
    };

    _this.getZoom = function() {
      return _this.state.zoom;
    };

    _this.lastCursor = null;
    // Last touch position
    _this.lastTouch = null;
    // Last touch time in milliseconds
    _this.lastTouchTime = 0;
    // Last double tap time (used to limit multiple double tap) in milliseconds
    _this.lastDoubleTapTime = 0;
    // Last calculated distance between two fingers in pixels
    _this.lastTouchDistance = null;
    // Last request animation frame identifier
    _this.lastRequestAnimationId = null;

    _this.state = (0, _extends3.default)({}, _this.constructor.defaultState, {
      transitionDuration: props.animDuration
    });
    return _this;
  }

  /**
   * Calculates new translate positions for CSS transformations.
   * @param  {Number} x     Relative (rect-based) X position in pixels
   * @param  {Number} y     Relative (rect-based) Y position in pixels
   * @param  {Number} zoom  Scale value
   * @return {Array}        New X and Y positions
   */

  /**
   * Calculates the narrowed shift for panning actions.
   * @param  {Number} shift      Initial shift in pixels
   * @param  {Number} minLimit   Minimum limit (left or top) in pixels
   * @param  {Number} maxLimit   Maximum limit (right or bottom) in pixels
   * @param  {Number} minElement Left or top element position in pixels
   * @param  {Number} maxElement Right or bottom element position in pixels
   * @return {Number}            Narrowed shift
   */

  /**
   * Determines cursor style.
   * @param  {Boolean} canMoveOnX Element can be panned on the X axis
   * @param  {Boolean} canMoveOnY Element can be panned on the Y axis
   * @return {String}             Cursor style
   */

  /**
   * Applies a full-zoom on the specified X and Y positions
   * @param  {Number} x Relative (rect-based) X position in pixels
   * @param  {Number} y Relative (rect-based) Y position in pixels
   */

  /**
   * Moves the element by incrementing its position with given X and Y values.
   * @param  {Number} shiftX             Position change to apply on X axis in pixels
   * @param  {Number} shiftY             Position change to apply on Y axis in pixels
   * @param  {Number} transitionDuration Transition duration (in seconds)
   */

  /**
   * Trigger a decelerating movement after a mouse up or a touch end event, using the last movement shift.
   * @param  {Number} lastShiftOnX Last shift on the X axis in pixels
   * @param  {Number} lastShiftOnY Last shift on the Y axis in pixels
   */

  /**
   * Event handler on scroll.
   * @param  {MouseEvent} event Mouse event
   */

  /**
   * Event handler on double click.
   * @param  {MouseEvent} event Mouse event
   */

  /**
   * Event handler on mouse down.
   * @param  {MouseEvent} event Mouse event
   */

  /**
   * Event handler on mouse move.
   * @param  {MouseEvent} event Mouse event
   */

  /**
   * Event handler on mouse up or mouse out.
   * @param  {MouseEvent} event Mouse event
   */

  /**
   * Event handler on touch start.
   * Zoom-in at the maximum scale if a double tap is detected.
   * @param  {TouchEvent} event Touch event
   */

  /**
   * Event handler on touch move.
   * Either move the element using one finger or zoom-in with a two finger pinch.
   * @param  {TouchEvent} event Touch move
   */

  /**
   * Event handler on touch end or touch cancel.
   * @param  {TouchEvent} event Touch move
   */

  /**
   * Increments the zoom with the given value.
   * @param  {Number} value Zoom value
   */

  /**
   * Decrements the zoom with the given value.
   * @param  {Number} value Zoom value
   */

  /**
   * Zoom-in on the specified zone with the given relative coordinates and dimensions.
   * @param  {Number} relX      Relative X position of the zone left-top corner in pixels
   * @param  {Number} relY      Relative Y position of the zone left-top corner in pixels
   * @param  {Number} relWidth  Zone width in pixels
   * @param  {Number} relHeight Zone height in pixels
   */

  /**
   * Resets the component to its initial state.
   */

  /**
   * Returns the current zoom value.
   * @return {Number} Zoom value
   */

  (0, _createClass3.default)(PrismaZoom, [
    {
      key: "componentDidUpdate",
      value: function componentDidUpdate(prevProps, prevState) {
        if (this.props.onZoomChange && this.state.zoom !== prevState.zoom) {
          this.props.onZoomChange(this.state.zoom);
        }
      }
    },
    {
      key: "render",
      value: function render() {
        var _props = this.props,
          className = _props.className,
          children = _props.children;
        var _state = this.state,
          zoom = _state.zoom,
          posX = _state.posX,
          posY = _state.posY,
          cursor = _state.cursor,
          transitionDuration = _state.transitionDuration;

        var style = (0, _extends3.default)({}, this.props.style, {
          transform: "translate3d(" + posX + "px, " + posY + "px, 0) scale(" + zoom + ")",
          transition: "transform ease-out " + transitionDuration + "s",
          cursor: cursor,
          touchAction: "none",
          willChange: "transform"
        });

        var attr = {
          ref: "layout",
          style: style,
          className: className,
          onWheel: this.handleMouseWheel,
          onDoubleClick: this.handleDoubleClick,
          onMouseDown: this.handleMouseStart,
          onMouseMove: this.handleMouseMove,
          onMouseUp: this.handleMouseStop,
          onMouseLeave: this.handleMouseStop,
          onTouchStart: this.handleTouchStart,
          onTouchMove: this.handleTouchMove,
          onTouchEnd: this.handleTouchStop,
          onTouchCancel: this.handleTouchStop
        };

        return _react2.default.createElement("div", attr, children);
      }
    }
  ]);
  return PrismaZoom;
})(_react.PureComponent);

PrismaZoom.propTypes = {
  children: _propTypes2.default.node.isRequired,
  className: _propTypes2.default.string,
  style: _propTypes2.default.object,
  minZoom: _propTypes2.default.number,
  maxZoom: _propTypes2.default.number,
  scrollVelocity: _propTypes2.default.number,
  onZoomChange: _propTypes2.default.func,
  leftBoundary: _propTypes2.default.number,
  rightBoundary: _propTypes2.default.number,
  topBoundary: _propTypes2.default.number,
  bottomBoundary: _propTypes2.default.number,
  animDuration: _propTypes2.default.number,
  doubleTouchMaxDelay: _propTypes2.default.number,
  decelerationDuration: _propTypes2.default.number,
  disabled: _propTypes2.default.bool
};
PrismaZoom.defaultProps = {
  // Class name to apply on the zoom wrapper
  className: null,
  // Style to apply on the zoom wrapper
  style: {},
  // Minimum zoom ratio
  minZoom: 1,
  // Maximum zoom ratio
  maxZoom: 5,
  // Zoom increment or decrement on each scroll wheel detection
  scrollVelocity: 0.2,
  // Function called each time the zoom value changes
  onZoomChange: null,
  // Left screen-relative boundary, used to limit panning zone
  leftBoundary: 0,
  // Right screen-relative boundary, used to limit panning zone
  rightBoundary: 0,
  // Top screen-relative boundary, used to limit panning zone
  topBoundary: 0,
  // Bottom screen-relative boundary, used to limit panning zone
  bottomBoundary: 0,
  // Animation duration (in seconds)
  animDuration: 0.25,
  // Max delay between two taps to consider a double tap (in milliseconds)
  doubleTouchMaxDelay: 300,
  // Decelerating movement duration after a mouse up or a touch end event (in milliseconds)
  decelerationDuration: 750,
  // disable dragEvent
  disabled: false
};
PrismaZoom.defaultState = {
  // Transform scale value property
  zoom: 1,
  // Transform translateX value property
  posX: 0,
  // Transform translateY value property
  posY: 0,
  // Cursor style property
  cursor: "auto"
};
exports.default = PrismaZoom;
