import React, { useState, useEffect } from "react";
import { useStoreon } from "storeon/react";
import shortid from "shortid";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import EventChoice from "modules/Main/Story/components/EventChoice";
import MentionBox from "modules/Main/Story/components/MentionBox";
import MediaAsset from "modules/Main/_components/MediaAsset";
import BlockList from "modules/Main/Story/components/BlockList";

import { saveElement, deleteElement, updateMentions } from "services/dbService";
import { updateAllMentions, getTypeFromId } from "services/utilities";
import { bgColors } from "services/theme";

const styles = (theme) => ({
  ...bgColors,
  topWrapper: {
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
  },
  topDiv: {
    margin: "0 auto",
    flexGrow: 1,
  },
  margined: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  titleInput: {
    margin: theme.spacing(3),
    display: "block",
    fontSize: "2em",
  },
  triggerSelect: {
    minWidth: 400,
    maxWidth: "100%",
  },
  blocksList: {
    width: "100%",
    overflow: "hidden",
  },
  blockTitle: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    flexWrap: "wrap",
    borderBottom: "1px solid #333",
  },
  blockCharacterIcon: {
    width: 50,
    height: 50,
    marginRight: 20,
  },
  blockCharacterName: {
    marginRight: 20,
    flexGrow: 1,
  },
  blockCharacterButton: {
    marginRight: 20,
    marginLeft: 20,
  },
  btnAddBlock: {
    display: "block",
    margin: "20px auto",
  },
  characterIcon: {
    width: 30,
    height: 30,
    lineHeight: "30px",
    display: "inline-block",
    marginRight: 5,
    marginLeft: 5,
    verticalAlign: "middle",
    textAlign: "center",
  },
  fullWidth: {
    width: "100%",
  },
  lighterBg: {
    backgroundColor: "#4c4c4c",
    overflow: "visible",
    marginBottom: 10,
  },
  marTop: {
    marginTop: theme.spacing(3),
  },
  smallBtn: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  headerConditions: {
    display: "inline-block",
    padding: 5,
    background: "#666",
    marginRight: 10,
    marginLeft: 10,
  },
  explainer: {
    marginTop: 5,
    marginBottom: 25,
    fontSize: "1em",
    fontStyle: "italic",
  },
  expandHeader: {
    margin: "5px 0",
  },
  txtBtnInput: {
    marginTop: 30,
    marginRight: 70,
    marginBottom: 6,
    minWidth: 250,
  },
  depperBlock: {
    background: "#333333",
    padding: "5px 10px",
  },
  deeperBlockGrow: {
    background: "#333333",
    padding: "5px 10px",
    flexGrow: 1,
    margin: 10,
  },
  sectionTitle: {
    textAlign: "center",
    fontSize: "1.5em",
    fontWeight: "normal",
    borderTop: "1px solid #666",
    borderBottom: "1px solid #666",
    padding: 10,
    margin: theme.spacing(3),
    backgroundColor: "#2a2727",
  },
  blockClosed: {
    backgroundColor: "#343434",
    borderBottom: "1px solid #424242",
    borderTop: "1px solid #424242",
    "&:hover": {
      backgroundColor: "#424242",
    },
  },
  blockOpen: {
    backgroundColor: "#424242",
  },
  btnArea: {
    margin: theme.spacing(3),
    textAlign: "right",
  },
  fixedActions: {
    position: "fixed",
    bottom: 0,
    right: 0,
    width: "inherit",
    padding: 10,
    zIndex: 33,
    backgroundColor: "#303030",
  },
});

export default withStyles(styles)(({ classes }) => {
  /* eslint-disable no-unused-vars */
  const { dispatch, ui, router, error, currentStory } = useStoreon("ui", "router", "error", "currentStory");

  const [selectedTab, setSelectedTab] = useState(0);
  const [event, setEvent] = useState(null);
  const [isOpen, setIsOpen] = useState({ deleteConfirm: false });
  /* eslint-enable no-unused-vars */

  useEffect(() => {
    if (router.params.eventID) {
      const ev = JSON.parse(
        JSON.stringify({ ...currentStory.story.events.find((s) => s.id === router.params.eventID) })
      );
      delete ev.createdAt;
      delete ev.updatedAt;
      ev.trigger.after = ev.trigger.after ? ev.trigger.after : false;
      ev.music = ev.music ? ev.music : { category: "audio", file: null, pack: "user" };
      setEvent(ev);
      setSelectedTab(0);
      dispatch("router/setDirty", false);
    }
    return () => {
      setEvent(null);
    };
  }, [router.params, currentStory.story.events, dispatch]);

  const backToMap = () => {
    dispatch("router/setParams", { storypage: "dashboard", eventID: null });
  };

  const saveEventClick = async () => {
    dispatch("ui/setLoading", true);
    const oldEvent = currentStory.story.events.find((e) => e.id === event.id);
    await saveElement(
      currentStory.story,
      "events",
      event,
      currentStory.story.events.find((e) => e.id === event.id).name
    );
    dispatch("currentStory/updateElement", { type: "events", element: event });
    if (oldEvent.name !== event.name) {
      dispatch("currentStory/setStory", updateAllMentions(currentStory.story, oldEvent.name, event));
      updateMentions(currentStory.story);
    }
    dispatch("ui/setLoading", false);
    dispatch("router/setDirty", false);
  };

  const deleteEventClick = () => {
    dispatch("ui/setLoading", true);
    deleteElement(currentStory.story.id, "events", event)
      .then((deleted) => {
        dispatch("router/setDirty", false);
        dispatch("ui/setLoading", false);
        dispatch("router/setParams", { storypage: "dashboard", eventID: null });
        dispatch("currentStory/deleteElement", { type: "events", el: event });
      })
      .catch((error) => {
        dispatch("ui/setLoading", false);
        dispatch("error/setError", { visible: true, text: error.message });
      });
  };

  const updateEvent = (el, value) => {
    const newEvent = { ...event };
    newEvent[el] = value;
    setEvent({ ...newEvent });
    dispatch("router/setDirty", true);
  };

  const addChoice = (e) => {
    const newChoice = {
      buttonTxt: "Choice1",
      blocks: [
        {
          id: shortid.generate(),
          buttonTxt: "Continue",
          introTxt: "",
          mainTxt: "",
          characterID: null,
          clues: [],
          objectives: [],
          objectivesTxt: "",
          completes: [],
          completesTxt: "",
        },
      ],
      isEnd: false,
      canUndo: false,
    };
    // TODO: see issues cause we set it to old length and then increase new length
    const goToTab = event.choices.length;
    setEvent({ ...event, choices: [...event.choices, newChoice] });
    setSelectedTab(goToTab);
    dispatch("router/setDirty", true);
  };

  const triggerMentionsChange = (newValue, mentions) => {
    let newMentions = mentions.map((m) => {
      return { id: m.id, display: m.display, type: getTypeFromId(currentStory.story, m.id) };
    });
    newMentions = newMentions.filter((obj, pos, arr) => arr.map((mapObj) => mapObj.id).indexOf(obj.id) === pos);
    const newTrigger = {
      ...event.trigger,
      elements: newMentions,
      elementsTxt: newValue,
    };
    updateEvent("trigger", newTrigger);
  };

  return (
    <React.Fragment>
      {event && (
        <Typography component="div">
          <Paper className={classes.fixedActions} elevation={12}>
            <Button type="submit" variant="contained" color="default" className={classes.margined} onClick={backToMap}>
              Return to Map
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              className={classes.margined}
              onClick={saveEventClick}
            >
              Save Event
            </Button>
          </Paper>
          <div className={classes.topWrapper}>
            <div className={classes.margined}>
              {event.asset && (
                <MediaAsset
                  assetObject={event.asset}
                  halfImage={false}
                  size={200}
                  color="events"
                  assetChanged={(e) => updateEvent("asset", e)}
                />
              )}
            </div>
            <div className={classes.topDiv}>
              <Input
                value={event.name}
                onChange={(e) => updateEvent("name", e.target.value)}
                className={classes.titleInput}
              />

              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.margined}
                onClick={() => setIsOpen({ ...isOpen, deleteConfirm: true })}
              >
                Delete Event
              </Button>

              {event.music && (
                <MediaAsset
                  assetObject={event.music}
                  hideSelects={true}
                  halfImage={true}
                  inline={true}
                  color="elements"
                  assetChanged={(e) => updateEvent("music", e)}
                />
              )}

              {event.trigger && (
                <div className={classes.margined}>
                  <FormControl margin="normal" className={classes.triggerSelect}>
                    <InputLabel shrink={true}>Triggers When</InputLabel>
                    <Select
                      value={event.trigger.type}
                      onChange={(e) => updateEvent("trigger", { ...event.trigger, type: e.target.value })}
                    >
                      <MenuItem value="turn">At the start of turn X</MenuItem>
                      <MenuItem value="elementsTotal">When specific elements are discovered in the game</MenuItem>
                      <MenuItem value="elementsEach">When EACH Player discovers specific elements</MenuItem>
                    </Select>
                  </FormControl>
                  {event.trigger && (event.trigger.type === "turn" || event.trigger.after) && (
                    <FormControl margin="normal" style={{ marginLeft: 20 }}>
                      <InputLabel shrink={true}>Turn Number</InputLabel>
                      <Input
                        type="number"
                        value={event.trigger.turnNumber}
                        onChange={(e) =>
                          updateEvent("trigger", { ...event.trigger, turnNumber: parseInt(e.target.value) })
                        }
                      />
                    </FormControl>
                  )}
                  {event.trigger && event.trigger.type !== "turn" && (
                    <div className={classes.depperBlock}>
                      <MentionBox
                        value={event.trigger.elementsTxt}
                        onChange={(event, newValue, newPlainTextValue, mentions) =>
                          triggerMentionsChange(newValue, mentions)
                        }
                        elements={{
                          locations: true,
                          topics: true,
                          characters: true,
                          objectives: false,
                          players: false,
                        }}
                      />
                      <Typography component="p" className={classes.explainer}>
                        Use @ to add a condition for a Location, # for a Topic, and ^ for a Character.
                      </Typography>
                      <FormControlLabel
                        value="after"
                        control={
                          <Switch
                            checked={event.trigger.after}
                            onChange={(e) => updateEvent("trigger", { ...event.trigger, after: e.target.checked })}
                          />
                        }
                        label="Trigger X turns after elements discovered."
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <h3 className={classes.sectionTitle}>Conversation Blocks:</h3>
          <BlockList parentObj={event} hide={{ conditions: true }} />
          <h3 className={classes.sectionTitle}>Choices:</h3>

          <div>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={addChoice}
              className={classes.btnAddBlock}
            >
              ADD CHOICE
            </Button>
          </div>

          <Paper square>
            <Tabs
              value={selectedTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(event, newTab) => setSelectedTab(newTab)}
            >
              {event.choices &&
                event.choices.map((choice, choice_index) => (
                  <Tab key={`choiceTab_${choice_index}`} value={choice_index} label={choice.buttonTxt} />
                ))}
            </Tabs>
          </Paper>
          {event.choices &&
            event.choices.map((choice, choice_index) => (
              <Paper key={`choice_${choice_index}`} elevation={2}>
                {selectedTab === choice_index && (
                  <EventChoice
                    event={event}
                    choiceObj={choice}
                    choice_index={choice_index}
                    setSelectedTab={setSelectedTab}
                  />
                )}
              </Paper>
            ))}

          <Dialog open={isOpen.deleteConfirm} onClose={() => setIsOpen({ ...isOpen, deleteConfirm: false })}>
            <DialogTitle>{"Are you sure you want to DELETE this Event?"}</DialogTitle>
            <DialogContent>
              <DialogContentText>Event and all its data will be removed. Action cannot be undone.</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => setIsOpen({ ...isOpen, deleteConfirm: false })}
                color="secondary"
              >
                Cancel
              </Button>
              <Button variant="contained" onClick={deleteEventClick} color="primary">
                DELETE
              </Button>
            </DialogActions>
          </Dialog>
        </Typography>
      )}
    </React.Fragment>
  );
});
