import React, { useState, useEffect } from "react";
import { useStoreon } from "storeon/react";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import MediaAsset from "modules/Main/_components/MediaAsset";
import BlockList from "modules/Main/Story/components/BlockList";

import { saveElement, deleteElement, updateMentions } from "services/dbService";
import { updateAllMentions } from "services/utilities";
import { bgColors } from "services/theme";

const styles = (theme) => ({
  ...bgColors,
  textCenter: {
    textAlign: "center",
  },
  topWrapper: {
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
  },
  topDiv: {
    margin: "0 auto",
    flexGrow: 1,
  },
  mt1: {
    marginTop: 10,
  },
  margined: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  titleInput: {
    margin: theme.spacing(3),
    display: "block",
    fontSize: "1.2em",
  },
  sectionTitle: {
    textAlign: "center",
    fontSize: "1.5em",
    fontWeight: "normal",
    borderTop: "1px solid #666",
    borderBottom: "1px solid #666",
    padding: 10,
    backgroundColor: "#2a2727",
  },
  fixedActions: {
    position: "fixed",
    bottom: 0,
    right: 0,
    width: "inherit",
    padding: 10,
    zIndex: 33,
    backgroundColor: "#303030",
  },
  ddlTop: {
    display: "inline-block",
    "& .ddlLabel": {
      marginBottom: 5,
    },
    "& .MuiInput-root": {
      width: "100%",
    },
  },
});

export default withStyles(styles)(({ classes }) => {
  const { dispatch, auth, router, currentStory } = useStoreon("auth", "router", "currentStory");
  const [location, setLocation] = useState(null);
  const [isOpen, setIsOpen] = useState({ deleteConfirm: false });
  const maxCharacters = 800;

  useEffect(() => {
    if (router.params.locationID) {
      const loc = JSON.parse(
        JSON.stringify({ ...currentStory.story.locations.find((s) => s.id === router.params.locationID) })
      );
      delete loc.createdAt;
      delete loc.updatedAt;
      loc.music = loc.music ? loc.music : { category: "audio", file: null, pack: "user" };
      loc.puzzle = loc.puzzle && loc.puzzle.id ? loc.puzzle : auth.puzzles.find((p) => p.id === "ImageShuffle");
      setLocation(loc);
      dispatch("router/setDirty", false);
    }
    return () => {
      // setLocation(null);
    };
  }, [router.params, currentStory.story.locations, dispatch, auth.puzzles]);

  const backToMap = () => {
    dispatch("router/setParams", { storypage: "dashboard", locationID: null });
  };

  const saveLocationClick = async () => {
    dispatch("ui/setLoading", true);
    const oldLoc = currentStory.story.locations.find((e) => e.id === location.id);
    await saveElement(currentStory.story, "locations", location, "");
    dispatch("currentStory/updateElement", { type: "locations", element: location });
    if (oldLoc.name !== location.name) {
      dispatch("currentStory/setStory", updateAllMentions(currentStory.story, oldLoc.name, location));
      updateMentions(currentStory.story);
    }
    dispatch("ui/setLoading", false);
    dispatch("router/setDirty", false);
  };

  const deleteLocationClick = () => {
    dispatch("ui/setLoading", true);
    deleteElement(currentStory.story.id, "locations", location)
      .then((deleted) => {
        dispatch("router/setDirty", false);
        dispatch("ui/setLoading", false);
        dispatch("currentStory/deleteElement", { type: "locations", el: location });
        dispatch("router/setParams", { storypage: "dashboard", locationID: null });
      })
      .catch((error) => {
        dispatch("ui/setLoading", false);
        dispatch("error/setError", { visible: true, text: error.message });
      });
  };

  const updateLocation = (el, value) => {
    const newLoc = { ...location };
    newLoc[el] = value;
    setLocation({ ...newLoc });
    dispatch("router/setDirty", true);
  };

  const checkMaxAndUpdateDescription = (value) => {
    if (value.length <= maxCharacters) {
      setLocation({ ...location, description: value });
    }
  };

  const getPuzzleOptions = (attr, id) => {
    const puzzle = auth.puzzles.find((p) => p.id === id);
    const options = puzzle ? Object.keys(puzzle.options) : [];

    return options.map((opt, i) => (
      <div key={i} className={classes.mt1}>
        <div>{opt}</div>
        <Input
          value={location[attr].options[opt].value}
          onChange={(e) => updatePuzzleOptions(attr, opt, e.target.value)}
        />
      </div>
    ));
  };

  const updatePuzzleOptions = (attr, opt, val) => {
    const newPuzzle = JSON.parse(JSON.stringify(location[attr]));
    newPuzzle.options[opt].value = val;
    updateLocation(attr, newPuzzle);
  };

  return (
    <React.Fragment>
      {location && (
        <Typography component="div">
          <div className={classes.fixedActions}>
            <Button type="submit" variant="contained" color="default" className={classes.margined} onClick={backToMap}>
              Return to Map
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              className={classes.margined}
              onClick={saveLocationClick}
            >
              Save Location
            </Button>
          </div>
          <div className={classes.topWrapper}>
            <div className={`${classes.margined} ${classes.textCenter}`}>
              {location.asset && (
                <MediaAsset
                  assetObject={location.asset}
                  halfImage={false}
                  size={200}
                  color="locations"
                  assetChanged={(e) => updateLocation("asset", e)}
                />
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.margined}
                onClick={() => setIsOpen({ ...isOpen, deleteConfirm: true })}
              >
                Delete Location
              </Button>
            </div>
            <div className={classes.topDiv}>
              <Input
                value={location.name}
                onChange={(e) => updateLocation("name", e.target.value)}
                className={classes.titleInput}
              />
              <Input
                multiline={true}
                value={location.description}
                onChange={(e) => checkMaxAndUpdateDescription(e.target.value)}
                className={classes.titleInput}
              />
              <div className={classes.margined}>
                {maxCharacters - (location.description ? location.description.length : 0)}
              </div>

              <div className={classes.topWrapper}>
                {location.music && (
                  <MediaAsset
                    assetObject={location.music}
                    hideSelects={true}
                    halfImage={true}
                    inline={true}
                    color="elements"
                    assetChanged={(e) => updateLocation("music", e)}
                  />
                )}

                <div className={`${classes.margined} ${classes.ddlTop}`}>
                  <div className="ddlLabel">User must first solve puzzle</div>
                  <Select
                    value={location.puzzleGate ? location.puzzleGate.id : ""}
                    onChange={(e) =>
                      updateLocation(
                        "puzzleGate",
                        auth.puzzles.find((p) => p.id === e.target.value)
                      )
                    }
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {auth.puzzles.map((puzzle) => (
                      <MenuItem key={puzzle.id} value={puzzle.id}>
                        {puzzle.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {location.puzzleGate && getPuzzleOptions("puzzleGate", location.puzzleGate.id)}
                </div>

                <div className={`${classes.margined} ${classes.ddlTop}`}>
                  <div className="ddlLabel">
                    # wrong answers
                    <br />
                    before puzzle
                  </div>
                  <Select
                    value={location.puzzleMistakes ? location.puzzleMistakes : ""}
                    onChange={(e) => updateLocation("puzzleMistakes", e.target.value)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                  </Select>
                </div>

                <div className={`${classes.margined} ${classes.ddlTop}`}>
                  <div className="ddlLabel">
                    Puzzle to show
                    <br />
                    on wrong answers
                  </div>
                  <Select
                    value={location.puzzle ? location.puzzle.id : ""}
                    onChange={(e) =>
                      updateLocation(
                        "puzzle",
                        auth.puzzles.find((p) => p.id === e.target.value)
                      )
                    }
                  >
                    {auth.puzzles.map((puzzle) => (
                      <MenuItem key={puzzle.id} value={puzzle.id}>
                        {puzzle.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {location.puzzle && getPuzzleOptions("puzzle", location.puzzle.id)}
                </div>
              </div>
            </div>
          </div>
          <h3 className={classes.sectionTitle}>Conversation Blocks:</h3>
          <BlockList parentObj={location} />

          <Dialog open={isOpen.deleteConfirm} onClose={() => setIsOpen({ ...isOpen, deleteConfirm: false })}>
            <DialogTitle>{"Are you sure you want to DELETE this Location?"}</DialogTitle>
            <DialogContent>
              <DialogContentText>All Location Blocks will be removed. Action cannot be undone.</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => setIsOpen({ ...isOpen, deleteConfirm: false })}
                color="secondary"
              >
                Cancel
              </Button>
              <Button variant="contained" onClick={deleteLocationClick} color="primary">
                DELETE
              </Button>
            </DialogActions>
          </Dialog>
        </Typography>
      )}
    </React.Fragment>
  );
});
