import React, { useState } from "react";
import { useStoreon } from "storeon/react";

import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";

import { AuthLogout, saveStoryPublish, removeStoryPublish } from "services/dbService";

import StoriesList from "modules/Main/StoriesList/StoriesList";
import CreateStory from "modules/Main/Story/CreateStory";
import Story from "modules/Main/Story/Story";
import StoryGraph from "modules/Main/StoryGraph/StoryGraph";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" {...props} ref={ref} />);

const styles = (theme) => ({
  root: {
    position: "relative",
    overflow: "hidden",
  },
  appBarTitle: {
    flexGrow: 1,
    fontSize: "1.1em",
  },
  optionButton: {
    marginLeft: 15,
  },
  margined: {
    margin: "30px 20px 60px 20px",
    minWidth: 360,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  content: {
    position: "relative",
  },
  toolbar: theme.mixins.toolbar,
  btnPreview: {
    marginLeft: 45,
  },
  quickToggle: {
    marginLeft: 20,
  },
  drawerMenuButton: {
    marginLeft: -12,
    marginRight: 20,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  versioning: {
    marginLeft: 20,
  },
});

export default withStyles(styles)(({ classes }) => {
  /* eslint-disable no-unused-vars */
  const { dispatch, auth, router, currentStory } = useStoreon("auth", "router", "currentStory");

  const [openPublish, setOpenPublish] = useState(false);
  const [openGraph, setOpenGraph] = useState(false);
  const [accountEl, setAccountEl] = useState(null);
  const [optionsEl, setOptionsEl] = useState(null);
  const [toggleMenu, setToggleMenu] = useState(false);
  /* eslint-enable no-unused-vars */

  const exportJSON = () => {
    const name = `${currentStory.story.name}.json`;
    const text = JSON.stringify(currentStory.story);

    const a = document.createElement("a");
    const type = name.split(".").pop();
    a.href = URL.createObjectURL(new Blob([text], { type: `text/${type === "txt" ? "plain" : type}` }));
    a.download = name;
    a.click();
    a.remove();
  };

  const callToggleMenu = () => {
    setToggleMenu(!toggleMenu);
  };

  const publishStory = async () => {
    dispatch("ui/setLoading", true);
    await saveStoryPublish(currentStory.story);
    dispatch("ui/setLoading", false);
  };

  const unpublishStory = async () => {
    dispatch("ui/setLoading", true);
    await removeStoryPublish(currentStory.story.id);
    dispatch("ui/setLoading", false);
  };

  const AccountMenu = (
    <React.Fragment>
      {auth.user && auth.user.email}
      <IconButton
        aria-owns={Boolean(accountEl) ? "menu-appbar" : undefined}
        aria-haspopup="true"
        onClick={(e) => setAccountEl(e.currentTarget)}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu id="menu-appbar" anchorEl={accountEl} open={Boolean(accountEl)} onClose={() => setAccountEl(null)}>
        <MenuItem onClick={() => setAccountEl(null)}>Profile</MenuItem>
        <MenuItem onClick={() => setAccountEl(null)}>My account</MenuItem>
        <MenuItem onClick={() => AuthLogout()}>Logout</MenuItem>
      </Menu>
    </React.Fragment>
  );

  const OptionsMenu = (
    <React.Fragment>
      <IconButton
        aria-owns={Boolean(optionsEl) ? "options-appbar" : undefined}
        aria-haspopup="true"
        onClick={(e) => setOptionsEl(e.currentTarget)}
        color="inherit"
        className={classes.optionButton}
      >
        <ArrowDropDown />
      </IconButton>
      <Menu
        id="options-appbar"
        anchorEl={optionsEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        getContentAnchorEl={null}
        open={Boolean(optionsEl)}
        onClose={() => setOptionsEl(null)}
      >
        <MenuItem
          onClick={() => {
            setOptionsEl(null);
            setOpenGraph(true);
          }}
        >
          View Story Graph
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOptionsEl(null);
            exportJSON();
          }}
        >
          Export JSON
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOptionsEl(null);
            setOpenPublish(true);
          }}
        >
          Publish Story
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOptionsEl(null);
            dispatch("router/routeTo", {
              page: "storiesList",
              title: "Dashboard",
              id: 0,
            });
            dispatch("currentStory/setStory", { story: {} });
          }}
        >
          Back to Story Selection
        </MenuItem>
      </Menu>
    </React.Fragment>
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          {router.page === "story" && (
            <IconButton
              className={classes.drawerMenuButton}
              color="inherit"
              aria-label="Menu"
              onClick={() => setToggleMenu(!toggleMenu)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography component="div" color="inherit" className={classes.appBarTitle}>
            {router.title} {currentStory.story.version && `(v${currentStory.story.version.number})`}
            {router.page === "story" && OptionsMenu}
          </Typography>
          {AccountMenu}
        </Toolbar>
      </AppBar>
      <div className={classes.toolbar} />
      <div className={classes.content}>
        {router.page === "storiesList" && <StoriesList />}
        {router.page === "createStory" && <CreateStory />}
        {router.page === "story" && <Story toggleMenu={toggleMenu} callToggleMenu={callToggleMenu} />}
      </div>

      <Dialog open={openGraph} fullScreen TransitionComponent={Transition} onClose={() => setOpenGraph(false)}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" onClick={() => setOpenGraph(false)} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit">
              {" "}
              Locations Graph - {router.title}{" "}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.toolbar} />
        <StoryGraph />
      </Dialog>

      <Dialog open={openPublish} onClose={() => setOpenPublish(false)}>
        <DialogTitle id="simple-dialog-title">Publish Story</DialogTitle>
        <DialogContent>
          <div>
            <Button onClick={unpublishStory} color="primary" variant="contained" className={classes.btnPreview}>
              {" "}
              Unpublish Story{" "}
            </Button>
            <Button onClick={publishStory} color="secondary" variant="contained" className={classes.btnPreview}>
              {" "}
              Publish Story{" "}
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPublish(false)} color="primary">
            {" "}
            Close{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});
