import React, { useState, useEffect, useCallback } from "react";
import { useStoreon } from "storeon/react";

import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import DeleteIcon from "@material-ui/icons/Delete";

import MasonryLayout from "modules/Main/_components/MasonryLayout";
import DropzoneElement from "modules/Main/_components/DropzoneElement";

import { getAssetUrl, getDateFromAll } from "services/utilities";
import { getAssetPack, deleteImageFromAssetPack } from "services/dbService";
import { bgColors } from "services/theme";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" {...props} ref={ref} />);

const styles = (theme) => ({
  ...bgColors,
  mediaRoot: {
    /*display: 'flex',*/
    position: "relative",
    /*alignItems: 'center',*/
  },
  innerDiv: {
    margin: "10px auto",
    textAlign: "center",
  },
  margined: {
    display: "inline-block",
    marginLeft: 15,
  },
  selectIcon: {
    width: 30,
    height: 30,
    display: "inline-block",
    marginRight: 10,
    verticalAlign: "middle",
  },
  halfImage: {
    height: "auto",
    borderRadius: "0",
    minWidth: 250,
  },
  masonItem: {
    position: "relative",
    display: "block",
    transition: "0.8s opacity",
    minHeight: 55,
    opacity: 1,
    "&:hover": {
      opacity: 0.6,
    },
  },
  masonImg: {
    width: "100%",
  },
  masonLegend: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    background: "rgba(0,0,0,0.8)",
    padding: "10px 20px",
    color: "#fff",
  },
  toolbar: theme.mixins.toolbar,
  extraMarginBottom: {
    marginBottom: 100,
  },
  min50: {
    minHeight: 50,
  },
  imageDeleteButton: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 2,
    backgroundColor: "rgba(0,0,0,0.8)",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.5)",
    },
  },
  audioPlayer: {
    display: "block",
    width: "80%",
    margin: "10px auto",
    height: 25,
    outline: "none",
  },
});

export default withStyles(styles)(({ classes, assetObject, size, color, assetChanged, halfImage, inline = false }) => {
  /* eslint-disable no-unused-vars */
  const { dispatch, cache, auth } = useStoreon("cache", "auth");
  const [asset, setAsset] = useState({ ...assetObject });
  const [selectedPack, setSelectedPack] = useState("user");
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("characters");
  const [tags, setTags] = useState([]);
  const [fileSelect, setFileSelect] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [imageDeleting, setImageDeleting] = useState(null);
  /* eslint-enable no-unused-vars */

  const loadAssets = useCallback(
    ({ pack = null, added = null }) => {
      //@TODO: check if is ok to add them to cache and call.
      const target = pack ? pack : auth.user.uid;
      if (cache.images[target]) {
        console.log("loading images from cache");
        let cachedImages = cache.images[target];
        if (added) {
          cachedImages = cachedImages.concat(added);
          sortAssets(cachedImages);
          dispatch("cache/setImages", { target, arr: cachedImages });
          dispatch("ui/setLoading", false);
        }
        setSelectedAssets(cachedImages);
      } else {
        dispatch("ui/setLoading", true);
        getAssetPack(pack).then((returnedImages) => {
          const images = returnedImages.images.map((im) => ({
            ...im,
            createdAt: (im.createdAt = getDateFromAll(im.createdAt)),
          }));
          sortAssets(images);
          setSelectedAssets(images);
          dispatch("ui/setLoading", false);
          dispatch("cache/setImages", { target, arr: images });
        });
      }
    },
    [dispatch, cache.images, auth.user.uid]
  );

  useEffect(() => {
    setAsset({ ...assetObject });
    setSelectedCategory(assetObject.category || assetObject.type);
    setSelectedPack(assetObject.pack && assetObject.pack.toString());
  }, [assetObject]);

  useEffect(() => {
    if (fileSelect === true) {
      const pack = selectedPack === "user" ? "" : selectedPack;
      loadAssets({ pack });
    }
  }, [selectedPack, dispatch, fileSelect, loadAssets]);

  const getAvatarClasses = () => {
    const extra = halfImage ? classes.halfImage : "";
    return `${classes.min50} ${classes[color + "Border"]} ${extra}`;
  };

  const sortAssets = (assets, by = "createdAt", dir = "desc") => {
    return assets.sort((a, b) => (dir === "desc" ? b[by] - a[by] : a[by] - b[by]));
  };

  const chooseFileClick = async (image) => {
    const { width, height } = await imageProcess(image.url);
    const newAsset = {
      file: image.url,
      pack: selectedPack,
      category: selectedCategory,
      width,
      height,
    };
    setAsset(newAsset);
    assetChanged(newAsset);
    setFileSelect(false);
  };

  const imageProcess = (url) => {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve({ width: img.width, height: img.height });
      img.onerror = () => resolve({ width: 0, height: 0 });
      img.src = url;
    });
  };

  const refreshUserList = ({ added = null }) => {
    loadAssets({ added });
  };

  const imageDeleteClick = (e, image) => {
    e.stopPropagation();
    setImageDeleting(image);
    setDeleteConfirm(true);
    return false;
  };

  const imageDeleteConfirm = () => {
    dispatch("ui/setLoading", true);
    deleteImageFromAssetPack(imageDeleting).then(() => {
      const newAssets = selectedAssets.filter((a) => a.id !== imageDeleting.id);
      setSelectedAssets(newAssets);
      setImageDeleting(null);
      setDeleteConfirm(false);
      dispatch("ui/setLoading", false);
      dispatch("cache/setImages", { target: auth.user.uid, arr: newAssets });
    });
  };

  return (
    <div className={classes.mediaRoot} style={{ display: inline ? "inline-block" : "block" }}>
      <div className={classes.innerDiv}>
        <div className={classes.innerDiv}>
          {asset && asset.file && (
            <Typography component="a" href={getAssetUrl(asset)} target="_blank" color="primary">
              <em>Open in new window</em>
            </Typography>
          )}
        </div>
        <ButtonBase onClick={() => setFileSelect(true)} aria-label="Close" className={classes.margined}>
          <Avatar
            className={getAvatarClasses()}
            style={size ? { width: `${size}px`, height: `${size}px` } : {}}
            src={asset.category !== "audio" ? getAssetUrl(asset) : null}
          >
            {asset.category === "audio" ? "Select audio file..." : ""}
          </Avatar>
        </ButtonBase>
        {asset.category === "audio" && asset.file && (
          <audio className={classes.audioPlayer} src={asset.file} controls />
        )}
      </div>

      <Dialog fullScreen open={fileSelect} onClose={() => setFileSelect(false)} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" onClick={() => setFileSelect(false)} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit">
              Select File
            </Typography>
            <FormControl className={classes.margined}>
              <InputLabel shrink={true}>Asset Pack</InputLabel>
              <Select value={selectedPack} onChange={(e) => setSelectedPack(e.target.value)}>
                <MenuItem value="1">Asset Pack 1</MenuItem>
                <MenuItem value="user">User Library</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.margined}>
              <InputLabel shrink={true}>Type</InputLabel>
              <Select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                <MenuItem value="locations">Locations</MenuItem>
                <MenuItem value="characters">Characters</MenuItem>
                <MenuItem value="topics">Topics</MenuItem>
                <MenuItem value="maps">Maps</MenuItem>
                <MenuItem value="covers">Covers</MenuItem>
                <MenuItem value="audio">Audio</MenuItem>
              </Select>
            </FormControl>
            <div>Also tags here</div>
          </Toolbar>
        </AppBar>
        <div className={`${classes.toolbar} ${selectedPack === "user" ? classes.extraMarginBottom : ""}`} />

        {selectedPack === "user" && (
          <DropzoneElement selectedCategory={selectedCategory} refreshUserList={refreshUserList} />
        )}

        <MasonryLayout columns={5} gap={5}>
          {selectedAssets &&
            selectedAssets
              .filter((a) => a.category === selectedCategory)
              .map((image) => (
                <Typography
                  key={image.id}
                  className={classes.masonItem}
                  component="a"
                  onClick={() => chooseFileClick(image)}
                  href="#"
                >
                  <img src={image.url} alt="" className={classes.masonImg} />
                  <div className={classes.masonLegend}>
                    {image.title}
                    <br />
                    Tags:{" "}
                    {image.tags &&
                      image.tags.map((tag, ind) => (
                        <span key={`tag_${tag}`}>{`"${tag}" ${image.tags.length - 1 === ind ? "" : ", "} `}</span>
                      ))}
                  </div>
                  {selectedPack === "user" && (
                    <IconButton className={classes.imageDeleteButton} onClick={(e) => imageDeleteClick(e, image)}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Typography>
              ))}
        </MasonryLayout>
      </Dialog>
      {selectedPack === "user" && (
        <Dialog open={deleteConfirm} onClose={() => setDeleteConfirm(false)}>
          <DialogTitle>{"Are you sure you want to DELETE this Image?"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Image will be removed. All elements referencing this image will show an empty image. Action cannot be
              undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => setDeleteConfirm(false)} color="secondary">
              Cancel
            </Button>
            <Button variant="contained" onClick={imageDeleteConfirm} color="primary">
              DELETE
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
});
