import React, { useEffect } from "react";
import { useStoreon } from "storeon/react";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Dialog from "@material-ui/core/Dialog";

import { getFullStory } from "services/dbService";

import StoryDashboard from "modules/Main/Story/Dashboard";
import StoryLocation from "modules/Main/Story/Location";
import StoryEvent from "modules/Main/Story/Event";
import StoryIntro from "modules/Main/Story/Intro";
import SideMenu from "./components/SideMenu";
import ModalElements from "./components/ModalElements";

const drawerWidth = 285;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#191d1c",
  },
  content: {
    flexGrow: 1,
  },
});

export default withStyles(styles)(({ classes, toggleMenu, callToggleMenu }) => {
  /* eslint-disable no-unused-vars */
  const { dispatch, ui, router, currentStory } = useStoreon("ui", "router", "currentStory");
  /* eslint-enable no-unused-vars */

  useEffect(() => {
    dispatch("ui/setLoading", true);
    getFullStory(router.id).then((fullStory) => {
      dispatch("ui/setLoading", false);
      dispatch("currentStory/setStory", fullStory);
    });
  }, [router.id, dispatch]);

  const closeModal = () => dispatch("router/closeModal", true);

  return (
    <Typography component="div" className={classes.root}>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          open={toggleMenu}
          onClose={callToggleMenu}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <SideMenu />
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          open
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <SideMenu />
        </Drawer>
      </Hidden>

      <main className={classes.content}>
        <Typography component="div">
          {router.params.storypage === "dashboard" && <StoryDashboard />}
          {router.params.storypage === "location" && <StoryLocation />}
          {router.params.storypage === "event" && <StoryEvent />}
          {router.params.storypage === "intro" && <StoryIntro />}
        </Typography>
      </main>
      <Dialog open={router.modalOpen === "ModalElements"} onClose={closeModal} fullWidth scroll="body">
        <ModalElements />
      </Dialog>
    </Typography>
  );
});
