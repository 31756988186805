import React, { useState, useEffect } from "react";
import { useStoreon } from "storeon/react";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Input from "@material-ui/core/Input";

import MediaAsset from "modules/Main/_components/MediaAsset";
import MentionBox from "modules/Main/Story/components/MentionBox";

import { updateAllMentions, getTypeFromId, findElementMentions } from "services/utilities";
import { textColors, bgColors } from "services/theme";
import { addElement, saveElement, deleteElement, updateMentions } from "services/dbService";

const styles = {
  ...textColors,
  ...bgColors,
  deleteArea: {
    marginTop: 20,
    marginBottom: 20,
  },
  button: {
    marginRight: 20,
  },
  upper: {
    textTransform: "uppercase",
  },
  mtop: {
    marginTop: 20,
  },
  explainer: {
    marginTop: 5,
    marginBottom: 25,
    fontSize: "0.8em",
    fontStyle: "italic",
  },
  introBlock: {
    margin: "15px auto",
    marginTop: 15,
  },
  inputDiv: {
    display: "flex",
  },
  input: {
    flexGrow: 1,
  },
};

export default withStyles(styles)(({ classes }) => {
  const { dispatch, router, currentStory } = useStoreon("router", "currentStory");
  const [element, setElement] = useState({});
  const [oldName, setOldName] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [mentionCounts, setMentionCounts] = useState({ count: 0, where: {} });

  const modalName = "ModalElements";
  const maxCharacters = 800;

  useEffect(() => {
    if (router && router.modalOptions && currentStory.story && router.modalOpen === modalName && router.modalID) {
      const el = currentStory.story[router.modalOptions.elementType].find((c) => c.id === router.modalID);
      setElement(el);
      setOldName(el.name);
      setMentionCounts(findElementMentions(currentStory.story, el));
    } else if (
      router &&
      router.modalOptions &&
      currentStory.story &&
      router.modalOpen === modalName &&
      router.modalID === 0
    ) {
      setElement({ ...router.modalOptions.fields });
    }
  }, [router, currentStory.story]);

  const closeModal = () => dispatch("router/closeModal", true);

  const saveModal = () => {
    dispatch("ui/setLoading", true);
    if (router.modalID === 0) {
      addElement(currentStory.story, router.modalOptions.elementType, element)
        .then(async (added) => {
          element.id = added.id;
          dispatch("currentStory/addElement", {
            type: router.modalOptions.elementType,
            element,
          });
          dispatch("ui/setLoading", false);
          closeModal();
        })
        .catch((err) => {
          dispatch("ui/setLoading", false);
          dispatch("error/setError", { visible: true, text: err.message });
        });
    } else {
      saveElement(currentStory.story, router.modalOptions.elementType, element, oldName)
        .then(async (updated) => {
          dispatch("currentStory/updateElement", {
            type: router.modalOptions.elementType,
            element,
            oldName,
          });
          if (oldName !== element.name) {
            dispatch("currentStory/setStory", updateAllMentions(currentStory.story, oldName, element));
            updateMentions(currentStory.story);
          }
          dispatch("ui/setLoading", false);
          closeModal();
        })
        .catch((err) => {
          dispatch("error/setError", { visible: true, text: err.message });
          dispatch("ui/setLoading", false);
        });
    }
  };

  const deleteElementClick = async () => {
    dispatch("ui/setLoading", true);
    deleteElement(currentStory.story.id, router.modalOptions.elementType, element)
      .then((deleted) => {
        dispatch("currentStory/deleteElement", {
          type: router.modalOptions.elementType,
          el: element,
        });
        dispatch("ui/setLoading", false);
        closeModal();
      })
      .catch((error) => {
        dispatch("ui/setLoading", false);
        dispatch("error/setError", { visible: true, text: error.message });
      });
  };

  const mentionsChange = (event, newValue, newPlainTextValue, mentions) => {
    let newMentions = mentions.map((m) => {
      return { id: m.id, display: m.display, type: getTypeFromId(currentStory.story, m.id) };
    });
    newMentions = newMentions.filter((obj, pos, arr) => arr.map((mapObj) => mapObj.id).indexOf(obj.id) === pos);
    setElement({ ...element, text: newValue, mentions: newMentions });
  };

  const checkMaxAndUpdate = (event, index, isPre) => {
    if (event.target.value.length <= maxCharacters) {
      const newIntro = isPre ? element.preIntro : element.postIntro;
      newIntro[index].text = event.target.value;
      const data = isPre ? { ...element, preIntro: newIntro } : { ...element, postIntro: newIntro };
      setElement(data);
    }
  };

  const addIntroBlock = (isPre) => {
    const newBlock = { text: "" };
    const newIntro = isPre ? [...element.preIntro, newBlock] : [...element.postIntro, newBlock];
    const data = isPre ? { ...element, preIntro: newIntro } : { ...element, postIntro: newIntro };
    setElement(data);
  };

  const removeIntroBlock = (index, isPre) => {
    const newBlocks = Array.from(isPre ? element.preIntro : element.postIntro);
    newBlocks.splice(index, 1);
    const data = isPre ? { ...element, preIntro: newBlocks } : { ...element, postIntro: newBlocks };
    setElement(data);
  };

  /*const mentionsFormat = (id, display, type) => {
    switch (type) {
      case "locations":
        return `@${display}`;
      case "topics":
        return `#${display}`;
      default:
        return display;
    }
  }*/

  return (
    <React.Fragment>
      {element && router.modalOptions && (
        <React.Fragment>
          <DialogTitle>
            <span className={classes.upper}>{router.modalOptions.elementType}:</span>{" "}
            {router.modalID ? element.name : "Add New"}
            <div style={{ fontSize: "0.7em", wordBreak: "break-all" }}>
              Used in {mentionCounts.count}: {JSON.stringify(mentionCounts.where)}
            </div>
          </DialogTitle>
          <DialogContent>
            {element && element.asset && (
              <MediaAsset
                assetObject={element.asset}
                size="150"
                color={router.modalOptions.elementType}
                assetChanged={(e) => setElement({ ...element, asset: e })}
              />
            )}
            {element.name !== undefined && (
              <TextField
                InputLabelProps={{ shrink: true }}
                type="text"
                margin="normal"
                fullWidth
                autoFocus
                label="Title"
                onChange={(e) => setElement({ ...element, name: e.target.value })}
                value={element.name}
              />
            )}
            {element.description !== undefined && (
              <TextField
                InputLabelProps={{ shrink: true }}
                type="text"
                margin="normal"
                fullWidth
                multiline
                label="Description"
                onChange={(e) => setElement({ ...element, description: e.target.value })}
                value={element.description}
              />
            )}

            {element.preIntro !== undefined && (
              <Typography component="p" className={classes.mtop}>
                Pre Intro Blocks
              </Typography>
            )}
            {element.preIntro !== undefined &&
              element.preIntro.map((block, index) => (
                <div key={`modalPreIntro_${index}`} className={classes.introBlock}>
                  <div className={classes.inputDiv}>
                    <Input
                      fullWidth
                      placeholder="...type a Pre-intro block"
                      className={classes.input}
                      multiline={true}
                      onChange={(e) => checkMaxAndUpdate(e, index, true)}
                      value={block.text}
                    />
                    <div>
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => removeIntroBlock(index, true)}
                        className={classes.margined}
                      >
                        Remove
                      </Button>
                    </div>
                  </div>
                  <div>{maxCharacters - block.text.length}</div>
                </div>
              ))}
            {element.preIntro !== undefined && (
              <div>
                <Button size="small" variant="contained" color="secondary" onClick={() => addIntroBlock(true)}>
                  ADD PRE INTRO BLOCK
                </Button>
              </div>
            )}

            {element.postIntro !== undefined && (
              <Typography component="p" className={classes.mtop}>
                Post Intro Blocks
              </Typography>
            )}
            {element.postIntro !== undefined &&
              element.postIntro.map((block, index) => (
                <div key={`modalPostIntro_${index}`} className={classes.introBlock}>
                  <div className={classes.inputDiv}>
                    <Input
                      fullWidth
                      placeholder="...type a Post-intro block"
                      className={classes.input}
                      multiline={true}
                      onChange={(e) => checkMaxAndUpdate(e, index, false)}
                      value={block.text}
                    />
                    <div>
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => removeIntroBlock(index, false)}
                        className={classes.margined}
                      >
                        Remove
                      </Button>
                    </div>
                  </div>
                  <div>{maxCharacters - block.text.length}</div>
                </div>
              ))}
            {element.postIntro !== undefined && (
              <div>
                <Button size="small" variant="contained" color="secondary" onClick={() => addIntroBlock(false)}>
                  ADD POST INTRO BLOCK
                </Button>
              </div>
            )}

            {element.legend !== undefined && (
              <TextField
                InputLabelProps={{ shrink: true }}
                type="text"
                margin="normal"
                fullWidth
                multiline
                label="Legend"
                onChange={(e) => setElement({ ...element, legend: e.target.value })}
                value={element.legend}
              />
            )}
            {element.parent !== undefined && (
              <TextField
                InputLabelProps={{ shrink: true }}
                select
                margin="normal"
                fullWidth
                label="Select"
                onChange={(e) => setElement({ ...element, parent: e.target.value })}
                value={element.parent}
                helperText="Parent Element"
              >
                <MenuItem key="0" value="0">
                  -No Parent-
                </MenuItem>
                {currentStory.story[router.modalOptions.elementType].map(
                  (option) =>
                    option.id !== element.id && (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    )
                )}
              </TextField>
            )}
            {element.belongsTo !== undefined && element.belongsTo.type && (
              <TextField
                InputLabelProps={{ shrink: true }}
                select
                margin="normal"
                fullWidth
                label="Belong To"
                onChange={(e) =>
                  setElement({
                    ...element,
                    belongsTo: {
                      type: element.belongsTo.type,
                      id: e.target.value,
                    },
                  })
                }
                value={element.belongsTo.id}
                helperText="This is exclusive only to the selected element."
              >
                <MenuItem key="0" value="0">
                  -None-
                </MenuItem>
                {currentStory.story[element.belongsTo.type].map(
                  (option) =>
                    option.id !== element.id && (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    )
                )}
              </TextField>
            )}

            {element.mentions !== undefined && (
              <div>
                <Typography component="p" className={classes.mtop}>
                  Private Starting Elements
                </Typography>
                <MentionBox
                  value={element.text}
                  onChange={mentionsChange}
                  elements={{
                    locations: true,
                    topics: true,
                    characters: true,
                    objectives: true,
                    players: false,
                  }}
                />
                <Typography component="p" className={classes.explainer}>
                  Use @ to add a mention to a Location, # for a Topic, ^ for a Character, % for a Player and * for an
                  Objective.
                </Typography>
              </div>
            )}

            {element.required !== undefined && (
              <FormControlLabel
                control={
                  <Switch
                    checked={element.required}
                    onChange={(e) => setElement({ ...element, required: e.target.checked })}
                    value="required"
                  />
                }
                label="Required"
              />
            )}

            {router.modalID !== 0 && (
              <div className={classes.deleteArea}>
                {confirmDelete && (
                  <Button
                    className={classes.button}
                    variant="contained"
                    onClick={() => setConfirmDelete(false)}
                    color="secondary"
                  >
                    CANCEL
                  </Button>
                )}
                {confirmDelete && (
                  <Button className={classes.button} variant="contained" onClick={deleteElementClick} color="primary">
                    CONFIRM
                  </Button>
                )}
                {!confirmDelete && (
                  <Button variant="contained" onClick={() => setConfirmDelete(true)} color="primary">
                    DELETE
                  </Button>
                )}
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal} color="primary">
              Cancel
            </Button>
            <Button onClick={saveModal} color="primary">
              {router.modalID ? "Update" : "Add"}
            </Button>
          </DialogActions>
        </React.Fragment>
      )}
    </React.Fragment>
  );
});
