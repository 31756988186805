import React, { useEffect, useCallback } from "react";
import { useStoreon } from "storeon/react";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";

import { AuthCheck } from "services/dbService";
import Auth from "modules/Auth";
import Main from "modules/Main/Main";

import "./App.css";

const theme = createTheme({
  palette: {
    type: "dark",
    primary: { main: "#CD5749" },
    secondary: { main: "#709754" },
    error: red,
    // Used by `getContrastText()` to maximize the contrast between the background and the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    /*text: {
      primary: "#ffffff",
      secondary: "#eeeeee",
      disabled: "#aaaaaa",
      hint: "#effefe",
    }*/
  },
  typography: {
    fontSize: 12,
  },
});

const App = () => {
  const { dispatch, ui, error, auth } = useStoreon("ui", "error", "auth");

  const checkAuthStatus = useCallback(async () => {
    dispatch("ui/setLoading", true);
    const user = await AuthCheck();
    dispatch("auth/setUser", user);
    dispatch("ui/setLoading", false);
    dispatch("auth/setLogged", user ? true : false);
  }, [dispatch]);

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]); // []  = run only once

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        {ui.loading === true && (
          <div className="full-over">
            <CircularProgress size={50} />
          </div>
        )}
        {auth.logged === true && <Main />}
        {auth.logged === false && <Auth />}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={error.visible}
          onClose={() => dispatch("error/showError", false)}
          ContentProps={{ "aria-describedby": "message-id" }}
          message={<span id="message-id">{error.text}</span>}
        />
      </div>
    </ThemeProvider>
  );
};

export default App;
