import React, { useState, useEffect } from "react";
import { useStoreon } from "storeon/react";

import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";

import { bgColors } from "services/theme";

import BlockList from "modules/Main/Story/components/BlockList";

const styles = (theme) => ({
  ...bgColors,
  margined: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  sectionTitle: {
    fontSize: "1.5em",
    fontWeight: "normal",
    borderTop: "1px solid #666",
    borderBottom: "1px solid #666",
    padding: 10,
  },
});
// {isOpen.players ? <ExpandLess color="primary" /> : <ExpandMore color="inherit" />}

export default withStyles(styles)(({ classes, event, choiceObj, choice_index, setSelectedTab }) => {
  /* eslint-disable no-unused-vars */
  const [choice, setChoice] = useState(null);
  const { dispatch, currentStory } = useStoreon("currentStory");
  /* eslint-enable no-unused-vars */

  useEffect(() => {
    if (choiceObj) {
      setChoice(choiceObj);
    }
  }, [choiceObj]);

  const updateChoice = (el) => {
    setChoice({ ...choice, ...el });
    Object.keys(el).forEach((key) => {
      event.choices[choice_index][key] = el[key];
    });
    dispatch("router/setDirty", true);
  };

  const deleteChoice = () => {
    setSelectedTab(0);
    event.choices.splice(choice_index, 1);
    dispatch("router/setDirty", true);
  };

  return (
    <React.Fragment>
      {choice && (
        <div>
          <div className={classes.margined}>
            <FormControl margin="normal" fullWidth>
              <InputLabel shrink={true}>Choice Option Text</InputLabel>
              <Input value={choice.buttonTxt} onChange={(e) => updateChoice({ buttonTxt: e.target.value })} />
            </FormControl>
          </div>
          <div className={classes.margined}>
            <FormControlLabel
              value="isEnd"
              control={<Switch checked={choice.isEnd} onChange={(e) => updateChoice({ isEnd: e.target.checked })} />}
              label="Ends Game?"
            />
            {choice.isEnd && (
              <FormControlLabel
                value="canUndo"
                control={
                  <Switch checked={choice.canUndo} onChange={(e) => updateChoice({ canUndo: e.target.checked })} />
                }
                label="Can Players Undo Choice?"
              />
            )}
          </div>
          <h3 className={classes.sectionTitle}>Choice Blocks:</h3>
          <BlockList parentObj={choice} hide={{ conditions: true }} />
          <hr />
          <Button
            size="small"
            variant="contained"
            color="primary"
            className={classes.margined}
            onClick={() => deleteChoice()}
          >
            DELETE CHOICE
          </Button>
        </div>
      )}
    </React.Fragment>
  );
});
