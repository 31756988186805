import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";

import { createStoreon } from "storeon";
import { StoreContext } from "storeon/react";

import authStore from "stores/auth";
import currentStoryStore from "stores/currentStory";
import errorStore from "stores/error";
import routerStore from "stores/router";
import storiesStore from "stores/stories";
import uiStore from "stores/ui";
import cacheStore from "stores/cache";

import App from "modules/App";

const store = createStoreon([authStore, currentStoryStore, errorStore, routerStore, storiesStore, uiStore, cacheStore]);

const rootElement = document.getElementById("root");
ReactDOM.render(
  <StoreContext.Provider value={store}>
    <App />
  </StoreContext.Provider>,
  rootElement
);

/*if (module.hot) {
  module.hot.accept("./modules/App", () => {
    const NextApp = require("./modules/App").default;
    ReactDOM.render(<NextApp />, rootElement);
  });
}*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
