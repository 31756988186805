import React, { useState, useEffect } from "react";
import { useStoreon } from "storeon/react";
import shortid from "shortid";

import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionActions from "@material-ui/core/AccordionActions";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Forward from "@material-ui/icons/Forward";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";

import { bgColors } from "services/theme";
import { getAssetUrl, getTypeFromId, getPlainTextFromMention } from "services/utilities";

import MentionBox from "modules/Main/Story/components/MentionBox";

const styles = (theme) => ({
  ...bgColors,
  margined: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  root: {
    backgroundColor: "#333",
  },
  blocksList: {
    width: "100%",
    overflow: "hidden",
  },
  blockTitle: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    flexWrap: "wrap",
    borderBottom: "1px solid #333",
  },
  blockCharacterIcon: {
    width: 50,
    height: 50,
    marginRight: 20,
  },
  blockCharacterName: {
    marginRight: 20,
    flexGrow: 1,
  },
  blockCharacterButton: {
    marginRight: 20,
    marginLeft: 20,
  },
  btnAddBlock: {
    display: "block",
    margin: "20px auto",
  },
  characterIcon: {
    width: 30,
    height: 30,
    lineHeight: "30px",
    display: "inline-block",
    marginRight: 5,
    marginLeft: 5,
    verticalAlign: "middle",
    textAlign: "center",
  },
  fullWidth: {
    width: "100%",
  },
  lighterBg: {
    backgroundColor: "#3c3c3c",
    overflow: "visible",
    marginBottom: 10,
  },
  marTop: {
    marginTop: theme.spacing(3),
  },
  smallBtn: {
    marginLeft: theme.spacing(3),
  },
  headerConditions: {
    display: "inline-block",
    padding: 5,
    background: "#666",
    marginRight: 10,
    marginLeft: 10,
  },
  headerResults: {
    display: "inline-block",
    padding: 5,
    background: "#445a4e",
    marginRight: 10,
    marginLeft: 10,
  },
  headerCompletes: {
    display: "inline-block",
    padding: 5,
    background: "#765151",
    marginRight: 10,
    marginLeft: 10,
  },
  explainer: {
    marginTop: 5,
    marginBottom: 25,
    fontSize: "1em",
    fontStyle: "italic",
  },
  expandHeader: {
    margin: "5px 0",
  },
  expandHeaderOutro: {
    margin: "5px 0",
    backgroundColor: "#683838",
  },
  expandHeader2: {
    margin: "5px 0",
    backgroundColor: "#383838",
  },
  txtBtnInput: {
    marginTop: 30,
    marginRight: 70,
    marginBottom: 6,
    minWidth: 250,
  },
  deeperBlock: {
    background: "#333333",
    padding: "5px 10px",
  },
  deeperBlockGrow: {
    background: "#333333",
    padding: "5px 10px",
    flexGrow: 1,
    margin: 10,
  },
});
// {isOpen.players ? <ExpandLess color="primary" /> : <ExpandMore color="inherit" />}

export default withStyles(styles)(({ classes, parentObj, hide = {} }) => {
  /* eslint-disable no-unused-vars */
  const [blocks, setBlocks] = useState(null);
  const [openBlock, setOpenBlock] = useState(-1);
  const { dispatch, currentStory } = useStoreon("currentStory");
  /* eslint-enable no-unused-vars */

  useEffect(() => {
    if (parentObj) {
      setBlocks(parentObj.blocks);
    }
  }, [parentObj]);

  const updateParentObj = () => {
    parentObj.blocks = blocks;
  };

  const addBlock = (e) => {
    const newBlock = {
      id: shortid.generate(),
      buttonTxt: "",
      conditions: [],
      introTxt: "",
      mainTxt: "",
      characterID: null,
      clues: [],
      objectives: [],
      objectivesTxt: "",
      completes: [],
      completesTxt: "",
    };
    const tempBlocks = [...blocks, newBlock];
    setBlocks(tempBlocks);
    setOpenBlock(tempBlocks.length - 1);
    dispatch("router/setDirty", true);
  };

  const updateBlock = (index, el) => {
    Object.keys(el).forEach((key) => {
      blocks[index][key] = el[key];
    });
    setBlocks([...blocks]);
    updateParentObj();
    dispatch("router/setDirty", true);
  };

  const deleteBlock = (block_ind) => {
    blocks.splice(block_ind, 1);
    setBlocks([...blocks]);
    updateParentObj();
    setOpenBlock(-1);
    dispatch("router/setDirty", true);
  };

  const reorderBlock = (e, from_index, to_index) => {
    e.stopPropagation();
    e.preventDefault();
    const block = { ...blocks[from_index] };
    blocks.splice(from_index, 1);
    blocks.splice(to_index, 0, block);
    setBlocks([...blocks]);
    updateParentObj();
    setOpenBlock(-1);
    dispatch("router/setDirty", true);
  };

  const duplicateBlock = (block_ind) => {
    const newBlock = JSON.parse(JSON.stringify(blocks[block_ind]));
    newBlock.id = shortid.generate();
    const blocksTemp = [...blocks];
    blocksTemp.splice(block_ind, 0, newBlock);
    setBlocks(blocksTemp);
    updateParentObj();
    dispatch("router/setDirty", true);
    setOpenBlock(openBlock + 1);
  };

  const addCondition = (block_ind) => {
    const newCondition = {
      type: "and",
      elements: [],
      text: "",
    };
    blocks[block_ind].conditions = [...blocks[block_ind].conditions, newCondition];
    setBlocks([...blocks]);
    updateParentObj();
    dispatch("router/setDirty", true);
  };

  const updateCondition = (block_ind, cond_ind, value) => {
    blocks[block_ind].conditions[cond_ind] = value;
    setBlocks([...blocks]);
    updateParentObj();
    dispatch("router/setDirty", true);
  };

  const deleteCondition = (block_ind, cond_ind) => {
    blocks[block_ind].conditions.splice(cond_ind, 1);
    setBlocks([...blocks]);
    updateParentObj();
    dispatch("router/setDirty", true);
  };

  const addClue = (block_ind) => {
    const newClue = {
      elements: [],
      text: "",
    };
    blocks[block_ind].clues = [...blocks[block_ind].clues, newClue];
    setBlocks([...blocks]);
    updateParentObj();
    dispatch("router/setDirty", true);
  };

  const updateClue = (block_ind, clue_index, value) => {
    blocks[block_ind].clues[clue_index] = value;
    setBlocks([...blocks]);
    updateParentObj();
    dispatch("router/setDirty", true);
  };

  const deleteClue = (block_ind, clue_index) => {
    blocks[block_ind].clues.splice(clue_index, 1);
    setBlocks([...blocks]);
    updateParentObj();
    dispatch("router/setDirty", true);
  };

  const getCharacterDetails = (block, block_index) => {
    const character =
      currentStory.story.characters &&
      block.characterID &&
      currentStory.story.characters.find((el) => el.id === block.characterID);
    const im = character && character.asset ? getAssetUrl(character.asset) : "";
    return (
      <div className={classes.blockTitle}>
        <Avatar src={im} className={`${classes.blockCharacterIcon}`} />
        {block_index > 0 && (
          <Tooltip title="Move Up">
            <IconButton aria-label="Delete" onClick={(e) => reorderBlock(e, block_index, block_index - 1)}>
              <KeyboardArrowUp />
            </IconButton>
          </Tooltip>
        )}
        {block_index < blocks.length - 1 && (
          <Tooltip title="Move Down">
            <IconButton aria-label="Move Down" onClick={(e) => reorderBlock(e, block_index, block_index + 1)}>
              <KeyboardArrowDown />
            </IconButton>
          </Tooltip>
        )}
        <Button size="small" variant="contained" color="default" className={classes.blockCharacterButton}>
          <em>{block.buttonTxt}</em>
        </Button>
        <div className={classes.blockCharacterName}>{character ? character.name : <em>None / Narrator</em>}</div>
        {getBlockHeaderConditions(block)} <Forward /> {getBlockHeaderResults(block)}
      </div>
    );
  };

  const storyElementAvatar = (type, id, index) => {
    const el = currentStory.story[type].find((e) => e.id === id);
    return (
      el && (
        <Tooltip title={el.name} key={index} placement="top">
          <Avatar
            src={el && el.asset ? getAssetUrl(el.asset) : ""}
            className={`${classes.characterIcon} ${classes[type + "Border"]}`}
          />
        </Tooltip>
      )
    );
  };

  const getBlockHeaderConditions = (block) => {
    return (
      block.conditions &&
      block.conditions.map((condition, c_ind) => (
        <div className={classes.headerConditions} key={c_ind}>
          {condition.elements &&
            condition.elements.map((elem, el_ind) => storyElementAvatar(elem.type, elem.id, el_ind))}
        </div>
      ))
    );
  };

  const blockResultAvatar = (type, id, index, color, border = false) => {
    const el = currentStory.story[type].find((e) => e.id === id);
    return (
      el && (
        <Tooltip title={el.name} key={index} placement="top">
          <Avatar
            src={el && el.asset ? getAssetUrl(el.asset) : ""}
            className={`${classes.characterIcon} ${classes[color + "Bg"]} ${border && classes[color + "Border"]}`}
          >
            {type === "clues" ? "C" : "O"}
          </Avatar>
        </Tooltip>
      )
    );
  };

  const getBlockHeaderResults = (block) => {
    return (
      <div>
        {block.clues &&
          block.clues.map((clue, clue_index) => (
            <Tooltip key={clue_index} title={getPlainTextFromMention(clue.text)}>
              <div className={`${classes.headerResults} ${classes.greenBorder}`}>
                {clue.elements && clue.elements.map((elem, el_ind) => storyElementAvatar(elem.type, elem.id, el_ind))}
              </div>
            </Tooltip>
          ))}
        {block.objectives && block.objectives.length > 0 && (
          <div className={classes.headerResults}>
            {block.objectives.map((obj, obj_ind) => blockResultAvatar(obj.type, obj.id, obj_ind, "green"))}
          </div>
        )}
        {block.completes && block.completes.length > 0 && (
          <div className={classes.headerCompletes}>
            {block.completes.map((compl, compl_ind) =>
              blockResultAvatar(compl.type, compl.id, compl_ind, compl.type, compl.type !== "objectives")
            )}
          </div>
        )}
      </div>
    );
  };

  const mentionsChnange = (newValue, mentions, block_index, target, target_index) => {
    let newMentions = mentions.map((m) => {
      return { id: m.id, display: m.display, type: getTypeFromId(currentStory.story, m.id) };
    });
    newMentions = newMentions.filter((obj, pos, arr) => arr.map((mapObj) => mapObj.id).indexOf(obj.id) === pos);
    switch (target) {
      case "clues":
        updateClue(block_index, target_index, { text: newValue, elements: newMentions });
        break;
      case "objectives":
        updateBlock(block_index, { objectives: newMentions, objectivesTxt: newValue });
        break;
      case "completes":
        updateBlock(block_index, { completes: newMentions, completesTxt: newValue });
        break;
      case "conditions":
        const mentionCount = newMentions.reduce((total, m) => {
          if (m.type !== "players") {
            total = total + 1;
          }
          return total;
        }, 0);
        if (mentionCount > 3) {
          return false;
        }
        updateCondition(block_index, target_index, { text: newValue, elements: newMentions });
        break;
      default:
        return false;
    }
  };

  return (
    <div className={classes.blocksList}>
      {blocks &&
        blocks.map((block, ind) => (
          <Accordion
            key={`block_${block.id}`}
            expanded={openBlock === ind}
            onChange={() => (openBlock === ind ? setOpenBlock(-1) : setOpenBlock(ind))}
            className={classes.root}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              classes={{
                content: block.isOutro ? classes.expandHeaderOutro : classes.expandHeader,
                expanded: classes.expandHeader2,
              }}
            >
              {getCharacterDetails(block, ind)}
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="div" className={classes.fullWidth}>
                {!hide.conditions && (
                  <div className={classes.deeperBlock}>
                    <h3>
                      Shown when user is or asks about (Conditions):
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.smallBtn}
                        onClick={() => addCondition(ind)}
                      >
                        Add Condition
                      </Button>
                    </h3>
                    {block.conditions &&
                      block.conditions.map((condition, c_ind) => (
                        <div key={c_ind}>
                          <Card className={classes.lighterBg}>
                            <CardContent>
                              <MentionBox
                                value={condition.text}
                                onChange={(event, newValue, newPlainTextValue, mentions) =>
                                  mentionsChnange(newValue, mentions, ind, "conditions", c_ind)
                                }
                                elements={{
                                  locations: true,
                                  topics: true,
                                  characters: true,
                                  objectives: false,
                                  players: true,
                                }}
                              />
                              <Typography component="p" className={classes.explainer}>
                                Use @ to add a condition for a Location, # for a Topic, ^ for a Character. Use % to note
                                that this block will only be shown to the specific Player.{" "}
                                <strong>MAX 3 ELEMENTS (excluding Players).</strong>
                              </Typography>
                            </CardContent>
                            <CardActions>
                              <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => deleteCondition(ind, c_ind)}
                              >
                                DELETE CONDITION
                              </Button>
                            </CardActions>
                          </Card>
                          {c_ind !== block.conditions.length - 1 && (
                            <Typography component="h6" variant="h6" style={{ textAlign: "center" }}>
                              -OR-
                            </Typography>
                          )}
                        </div>
                      ))}
                    <hr />
                  </div>
                )}
                <FormControl className={classes.txtBtnInput}>
                  <InputLabel shrink={true}>Trigger Button</InputLabel>
                  <Input
                    value={block.buttonTxt}
                    onChange={(e) => updateBlock(ind, { buttonTxt: e.target.value })}
                    name="block-button"
                    autoComplete="block-button"
                  />
                </FormControl>
                <FormControl margin="normal">
                  <InputLabel shrink={true}>Who Talks</InputLabel>
                  <Select
                    value={block.characterID || ""}
                    onChange={(e) => updateBlock(ind, { characterID: e.target.value })}
                  >
                    <MenuItem value="">
                      <em>None / Narration</em>
                    </MenuItem>
                    {currentStory.story.characters &&
                      currentStory.story.characters.length > 0 &&
                      currentStory.story.characters.map((character) => (
                        <MenuItem key={character.id} value={character.id}>
                          <Avatar
                            src={character ? getAssetUrl(character.asset) : ""}
                            className={`${classes.characterIcon}`}
                          />
                          {character.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {!hide.conditions && (
                  <FormControlLabel
                    value="isOutro"
                    control={
                      <Switch
                        checked={block.isOutro || false}
                        onChange={(e) => updateBlock(ind, { isOutro: e.target.checked })}
                      />
                    }
                    label="Is Outro?"
                  />
                )}
                <FormControl margin="normal" fullWidth>
                  <InputLabel shrink={true}>Intro Narrative</InputLabel>
                  <Input
                    multiline={true}
                    value={block.introTxt}
                    onChange={(e) => updateBlock(ind, { introTxt: e.target.value })}
                    name="block-intro"
                    autoComplete="block-intro"
                  />
                </FormControl>
                <FormControl margin="normal" fullWidth>
                  <InputLabel shrink={true}>Dialogue Text</InputLabel>
                  <Input
                    multiline={true}
                    value={block.mainTxt}
                    onChange={(e) => updateBlock(ind, { mainTxt: e.target.value })}
                    name="block-main"
                    autoComplete="block-main"
                  />
                </FormControl>

                <hr />

                <div className={classes.deeperBlock}>
                  <h3>
                    Clues received:
                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      className={classes.smallBtn}
                      onClick={() => addClue(ind)}
                    >
                      New Clue
                    </Button>
                  </h3>
                  <Typography component="p" className={classes.explainer}>
                    Write your clue here. Use free text and also use @ for a Location, # for a Topic, ^ for a Character
                    and % for a Player you want the player to learn about.
                  </Typography>
                  {block.clues &&
                    block.clues.map((clue, clue_index) => (
                      <div key={clue_index}>
                        <Card className={classes.lighterBg}>
                          <CardContent>
                            <MentionBox
                              value={clue.text}
                              onChange={(event, newValue, newPlainTextValue, mentions) =>
                                mentionsChnange(newValue, mentions, ind, "clues", clue_index)
                              }
                              elements={{
                                locations: true,
                                topics: true,
                                characters: true,
                                objectives: false,
                                players: true,
                              }}
                            />
                          </CardContent>
                          <CardActions>
                            <Button
                              size="small"
                              variant="contained"
                              color="primary"
                              onClick={() => deleteClue(ind, clue_index)}
                            >
                              DELETE CLUE
                            </Button>
                          </CardActions>
                        </Card>
                      </div>
                    ))}
                </div>

                <hr />

                <Grid container>
                  <Grid item className={classes.deeperBlockGrow}>
                    <h3>Received Objectives:</h3>
                    <MentionBox
                      value={block.objectivesTxt}
                      onChange={(event, newValue, newPlainTextValue, mentions) =>
                        mentionsChnange(newValue, mentions, ind, "objectives")
                      }
                      elements={{
                        locations: false,
                        topics: false,
                        characters: false,
                        objectives: true,
                        players: false,
                      }}
                    />
                    <Typography component="p" className={classes.explainer}>
                      Use <strong>*</strong> to add an Objective
                    </Typography>
                  </Grid>

                  <Grid item className={classes.deeperBlockGrow}>
                    <h3>Completed Elements:</h3>
                    <MentionBox
                      value={block.completesTxt}
                      onChange={(event, newValue, newPlainTextValue, mentions) =>
                        mentionsChnange(newValue, mentions, ind, "completes")
                      }
                      elements={{ locations: true, topics: true, characters: true, objectives: true, players: false }}
                    />
                    <Typography component="p" className={classes.explainer}>
                      Use * for an Objective, @ to add a condition for a Location, # for a Topic, ^ for a Character.
                      Completed Elements are removed from all Player's elements list.
                    </Typography>
                  </Grid>
                </Grid>
              </Typography>
            </AccordionDetails>
            <Divider />
            <AccordionActions>
              <Button size="small" variant="contained" color="secondary" onClick={() => duplicateBlock(ind)}>
                DUPLICATE BLOCK
              </Button>
              <Button size="small" variant="contained" color="primary" onClick={() => deleteBlock(ind)}>
                DELETE BLOCK
              </Button>
            </AccordionActions>
          </Accordion>
        ))}
      <div>
        <Button size="small" variant="contained" color="secondary" onClick={addBlock} className={classes.btnAddBlock}>
          ADD BLOCK
        </Button>
      </div>
    </div>
  );
});
