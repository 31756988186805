const StoriesStore = (store) => {
  store.on("@init", () => ({
    stories: {
      stories: null,
    },
  }));

  store.on("stories/setStories", ({ stories }, payload) => {
    const ret = { ...stories, stories: payload };
    return { stories: ret };
  });
};

export default StoriesStore;
