const UIStore = (store) => {
  store.on("@init", () => ({
    ui: {
      loading: false,
    },
  }));

  store.on("ui/setLoading", ({ ui }, payload) => {
    const ret = { ...ui, loading: payload };
    return { ui: ret };
  });
};

export default UIStore;
