import React from "react";
import { useStoreon } from "storeon/react";

import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import LocationIcon from "@material-ui/icons/Place";
import PlayerIcon from "@material-ui/icons/Person";
import TopicIcon from "@material-ui/icons/Chat";
import CharacterIcon from "@material-ui/icons/Group";
import ObjectiveIcon from "@material-ui/icons/Announcement";
import EventIcon from "@material-ui/icons/Event";
import Button from "@material-ui/core/Button";

import { bgColors } from "services/theme";

import SideMenuList from "modules/Main/Story/components/SideMenuList";

const styles = (theme) => ({
  ...bgColors,
  toolbar: theme.mixins.toolbar,
  introBtn: {
    display: "block",
    margin: "10px auto",
  },
});
// {isOpen.players ? <ExpandLess color="primary" /> : <ExpandMore color="inherit" />}

export default withStyles(styles)(({ classes }) => {
  /* eslint-disable no-unused-vars */
  const { dispatch } = useStoreon();
  /* eslint-enable no-unused-vars */

  return (
    <React.Fragment>
      <div className={classes.toolbar} />

      <List component="nav">
        <Button
          color="secondary"
          className={classes.introBtn}
          onClick={() => dispatch("router/setParams", { storypage: "intro" })}
        >
          Set Story Intro
        </Button>
        <SideMenuList Icon={LocationIcon} elementType="locations" />
        <SideMenuList Icon={EventIcon} elementType="events" />
        <SideMenuList Icon={PlayerIcon} elementType="players" />
        <SideMenuList Icon={TopicIcon} elementType="topics" />
        <SideMenuList Icon={CharacterIcon} elementType="characters" />
        <SideMenuList Icon={ObjectiveIcon} elementType="objectives" />
      </List>
    </React.Fragment>
  );
});
