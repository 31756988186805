const colors = {
  players: { main: "#584976", secondary: "#ffffff" },
  topics: { main: "#486d8d", secondary: "#ffffff" },
  characters: { main: "#9d9246", secondary: "#ffffff" },
  locations: { main: "#709754", secondary: "#ffffff" },
  events: { main: "#9e5454", secondary: "#ffffff" },
  elements: { main: "#cd5749", secondary: "#ffffff" },
  clues: { main: "#1b7a60", secondary: "#ffffff" },
  objectives: { main: "#ca6b33", secondary: "#ffffff" },
  green: { main: "#709754", secondary: "#ffffff" },
  red: { main: "#cd5749", secondary: "#ffffff" },
  white: { main: "#fff", secondary: "#000" }
};

const borderWidth = 3;

const textColors = {
  elementsTxt: {
    color: colors.elements.main
  },
  playersTxt: {
    color: colors.players.main
  },
  topicsTxt: {
    color: colors.topics.main
  },
  charactersTxt: {
    color: colors.characters.main
  },
  locationsTxt: {
    color: colors.locations.main
  },
  eventsTxt: {
    color: colors.events.main
  },
  objectivesTxt: {
    color: colors.objectives.main
  },
  cluesTxt: {
    color: colors.clues.main
  },
  greenTxt: {
    color: colors.green.main
  },
  redTxt: {
    color: colors.red.main
  },
  whiteTxt: {
    color: colors.white.main
  }
};

const bgColors = {
  elementsBg: {
    color: colors.elements.secondary,
    backgroundColor: colors.elements.main
  },
  elementsBorder: {
    border: `${borderWidth}px solid ${colors.elements.main}`
  },
  playersBg: {
    color: colors.players.secondary,
    backgroundColor: colors.players.main
  },
  playersBorder: {
    border: `${borderWidth}px solid ${colors.players.main}`
  },
  topicsBg: {
    color: colors.topics.secondary,
    backgroundColor: colors.topics.main
  },
  topicsBorder: {
    border: `${borderWidth}px solid ${colors.topics.main}`
  },
  charactersBg: {
    color: colors.characters.secondary,
    backgroundColor: colors.characters.main
  },
  charactersBorder: {
    border: `${borderWidth}px solid ${colors.characters.main}`
  },
  locationsBg: {
    color: colors.locations.secondary,
    backgroundColor: colors.locations.main
  },
  locationsBorder: {
    border: `${borderWidth}px solid ${colors.locations.main}`
  },
  eventsBg: {
    color: colors.events.secondary,
    backgroundColor: colors.events.main
  },
  eventsBorder: {
    border: `${borderWidth}px solid ${colors.events.main}`
  },
  cluesBg: {
    color: colors.clues.secondary,
    backgroundColor: colors.clues.main
  },
  cluesBorder: {
    border: `${borderWidth}px solid ${colors.clues.main}`
  },
  objectivesBg: {
    color: colors.objectives.secondary,
    backgroundColor: colors.objectives.main
  },
  objectivesBorder: {
    border: `${borderWidth}px solid ${colors.objectives.main}`
  },
  redBg: {
    color: colors.red.secondary,
    backgroundColor: colors.red.main
  },
  redBorder: {
    border: `${borderWidth}px solid ${colors.red.main}`
  },
  greenBg: {
    color: colors.green.secondary,
    backgroundColor: colors.green.main
  },
  greenBorder: {
    border: `${borderWidth}px solid ${colors.green.main}`
  },
  whiteBg: {
    color: colors.white.secondary,
    backgroundColor: colors.white.main
  },
  whiteBorder: {
    border: `${borderWidth}px solid ${colors.white.main}`
  }
};

const mentionStyles = {
  control: {
    backgroundColor: "#fff",
    fontSize: 12,
    fontWeight: "normal",
    margin: 0,
    padding: 0,
    width: "100%"
  },

  highlighter: {
    overflow: "hidden",
    letterSpacing: 0,
    lineHeight: 1.5,
    textAlign: "left",
    margin: 0,
    padding: 0,
    width: "100%"
  },

  input: {
    letterSpacing: 0,
    lineHeight: 1.5,
    textAlign: "left",
    margin: 0,
    padding: 0,
    width: "100%"
  },

  "&singleLine": {
    control: {
      display: "inline-block",
      width: 130,
      margin: 0,
      padding: 0
    },

    highlighter: {
      border: "2px inset transparent",
      margin: 0,
      padding: 0,
      width: "100%"
    },

    input: {
      border: "2px inset",
      margin: 0,
      padding: 0,
      width: "100%"
    }
  },

  "&multiLine": {
    control: {
      border: "none",
      borderBottom: "1px solid white",
      backgroundColor: "transparent",
      fontSize: "1.2em",
      margin: 0,
      padding: 0,
      width: "100%"
    },

    highlighter: {
      minHeight: 30,
      outline: 0,
      border: 0,
      margin: 0,
      padding: 0,
      width: "100%"
    },

    input: {
      minHeight: 30,
      outline: 0,
      border: 0,
      color: "white",
      margin: 0,
      padding: 0,
      width: "100%"
    }
  },

  suggestions: {
    list: {
      maxHeight: 100,
      overflow: "auto",
      position: "absolute",
      bottom: 14,
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 10
    },

    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      backgroundColor: "#222",
      color: "#fff",
      "&focused": {
        backgroundColor: "#444"
      }
    }
  }
};

const uploaderStyles = {
  baseStyle: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 5,
    borderRadius: 2,
    borderColor: "#222",
    borderStyle: "dashed",
    backgroundColor: "#3c3c3c",
    color: "#efefef",
    outline: "none",
    transition: "border .24s ease-in-out"
  },
  activeStyle: {
    borderColor: "#2196f3"
  },
  acceptStyle: {
    borderColor: "#00e676"
  },
  rejectStyle: {
    borderColor: "#ff1744"
  }
};

export { colors, textColors, bgColors, mentionStyles, uploaderStyles };
