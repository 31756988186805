import React, { useState, useEffect } from "react";
import PrismaZoom from "modules/Main/_components/PrismaZoom";
import Draggable from "react-draggable";
import { useStoreon } from "storeon/react";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Fab from "@material-ui/core/Fab";
import PlusIcon from "@material-ui/icons/Add";
import MinusIcon from "@material-ui/icons/Remove";

import { bgColors } from "services/theme";
import { getAssetUrl } from "services/utilities";
import { storyUpdate, saveElement } from "services/dbService";

const styles = {
  ...bgColors,
  root: {
    position: "relative",
    height: "100vh",
    overflow: "hidden",
    marginTop: -64,
    paddingTop: 64,
  },
  zoom: {
    position: "relative",
    width: "100%",
  },
  margined: {
    margin: 10,
  },
  storyMap: {
    position: "relative",
    zIndex: 1,
    width: "100%",
  },
  overMap: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    // background: "rgba(0,0,0,0.6)"
  },
  mapImage: {
    //width: '100%',
  },
  locationList: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 9,
    width: "100%",
    height: "100%",
    textAlign: "left",
  },
  location: {
    position: "absolute",
    top: 20,
    left: 20,
    textAlign: "center",
    fontSize: "1.2em",
    display: "inline-block",
    margin: 15,
    lineHeight: 1,
    zIndex: 2,
  },
  locationIcon: {
    width: 150,
    height: 150,
    borderWidth: 5,
  },
  locationName: {
    width: "100%",
  },
  badge: {
    width: 40,
    height: 40,
    top: 5,
    right: 5,
    borderRadius: "50%",
    fontSize: "1em",
    border: "3px solid #303030",
  },
  button: {
    background: "rgba(0,0,0,0.4)",
  },
  mapControls: {
    position: "fixed",
    bottom: 10,
    right: 10,
    width: 80,
    padding: 10,
    zIndex: 2,
  },
};

export default withStyles(styles)(({ classes }) => {
  /* eslint-disable no-unused-vars */
  const { dispatch, router, currentStory } = useStoreon("router", "currentStory");
  const [locSize, setLocSize] = useState(130);
  const [zoomTimer, setZoomTimer] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  /* eslint-enable no-unused-vars */

  useEffect(() => {
    setLocSize(currentStory.story.iconSizes || 130);
  }, [currentStory.story.iconSizes]);

  const changeIconSizes = (to) => {
    let newSize = to > 60 && to < 170 ? to : locSize;
    setLocSize(newSize);
    dispatch("currentStory/updateStory", { iconSizes: newSize });

    clearTimeout(zoomTimer);
    setZoomTimer(setTimeout(() => storyUpdate(currentStory.story.id, { iconSizes: newSize }), 1000));
  };

  let dragStarted = false;

  const handleLocationClick = (loc) => {
    setTimeout(() => {
      dispatch("router/setParams", {
        storypage: "location",
        locationID: loc.id,
      });
    }, 200);
  };

  const startDragging = () => {
    setIsDragging(true);
    dragStarted = true;
  };
  const stopDragging = (location, el) => {
    if (!dragStarted) {
      handleLocationClick(location);
    }
    location.coordinates.x = el.lastX;
    location.coordinates.y = el.lastY;
    saveElement(currentStory.story, "locations", location, "");
    setIsDragging(false);
    dragStarted = false;
  };

  return (
    <Typography component="div" className={classes.root}>
      <div className={classes.mapControls}>
        <Fab size="small" className={classes.margined} onClick={() => changeIconSizes(locSize + 10)}>
          <PlusIcon />
        </Fab>
        <Fab size="small" className={classes.margined} onClick={() => changeIconSizes(locSize - 10)}>
          <MinusIcon />
        </Fab>
      </div>
      <PrismaZoom className={classes.zoom} leftBoundary={285} topBoundary={64} disabled={isDragging}>
        {currentStory.story.map && (
          <div className={classes.storyMap}>
            <div className={classes.overMap} />
            <img className={classes.mapImage} src={getAssetUrl(currentStory.story.map)} alt="" />
          </div>
        )}
        {currentStory.story.locations &&
          currentStory.story.locations.map((location) => (
            <Draggable
              key={location.id}
              bounds={"parent"}
              defaultPosition={{
                x: location.coordinates.x,
                y: location.coordinates.y,
              }}
              onDrag={startDragging}
              onStop={(ev, el) => stopDragging(location, el)}
            >
              <div className={classes.location}>
                <IconButton className={classes.button}>
                  <Badge
                    badgeContent={location.blocks && location.blocks.length}
                    color="primary"
                    classes={{ badge: classes.badge }}
                  >
                    <Avatar
                      src={getAssetUrl(location.asset)}
                      className={`${classes.locationIcon} ${classes.locationsBorder}`}
                      style={{ width: locSize, height: locSize }}
                    />
                  </Badge>
                </IconButton>
                <div className={classes.locationName} style={{ width: locSize * 1.3 }}>
                  {location.name}
                </div>
              </div>
            </Draggable>
          ))}
      </PrismaZoom>
    </Typography>
  );
});
