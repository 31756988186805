const CurrentStoryStore = (store) => {
  store.on("@init", () => ({
    currentStory: {
      story: {},
    },
  }));

  store.on("currentStory/setStory", ({ currentStory }, story) => {
    return { currentStory: { ...currentStory, story: story } };
  });

  store.on("currentStory/updateStory", ({ currentStory }, payload) => {
    const updatedStory = { ...currentStory.story, ...payload };
    const ret = { ...currentStory, story: updatedStory };
    return { currentStory: ret };
  });

  store.on("currentStory/addElement", ({ currentStory }, payload) => {
    currentStory.story[payload.type].push(payload.element);
    const ret = { ...currentStory };
    return { currentStory: ret };
  });

  store.on("currentStory/updateElement", ({ currentStory }, payload) => {
    const newEl = payload.element;
    currentStory.story[payload.type] = currentStory.story[payload.type].map((el) => (el.id === newEl.id ? newEl : el));
    const ret = { ...currentStory };
    return { currentStory: ret };
  });

  store.on("currentStory/deleteElement", ({ currentStory }, payload) => {
    currentStory.story[payload.type] = currentStory.story[payload.type].filter((s) => s.id !== payload.el.id);
    const ret = { ...currentStory };
    return { currentStory: ret };
  });
};

export default CurrentStoryStore;
