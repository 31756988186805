import React, { useState, useEffect } from "react";
import { useStoreon } from "storeon/react";

import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

import MentionBox from "modules/Main/Story/components/MentionBox";
import MediaAsset from "modules/Main/_components/MediaAsset";

import { withStyles } from "@material-ui/core/styles";
import { bgColors } from "services/theme";
import { storyUpdate } from "services/dbService";
import { getTypeFromId } from "services/utilities";

const styles = (theme) => ({
  ...bgColors,
  topWrapper: {
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
  },
  margined: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  heading: {
    margin: "30px auto",
    textDecoration: "underline",
    textAlign: "center",
  },
  mentionsDiv: {
    maxWidth: 960,
    margin: "40px auto",
    padding: "0 20px",
  },
  introBlock: {
    margin: theme.spacing(3),
    marginTop: theme.spacing(2),
    display: "flex",
  },
  inputDiv: {
    marginLeft: theme.spacing(3),
    flexGrow: 1,
  },
  btnAddBlock: {
    display: "block",
    margin: "20px auto",
  },
  fixedActions: {
    position: "fixed",
    bottom: 0,
    right: 0,
    width: "inherit",
    padding: 10,
    zIndex: 33,
    backgroundColor: "#303030",
  },
  explainer: {
    marginTop: 5,
    marginBottom: 25,
    fontSize: "1em",
    fontStyle: "italic",
  },
});

export default withStyles(styles)(({ classes }) => {
  /* eslint-disable no-unused-vars */
  const [maxCharacters, setMaxCharacters] = useState(400);
  const { dispatch, ui, router, error, currentStory } = useStoreon("ui", "router", "error", "currentStory");

  const [intro, setIntro] = useState(null);
  const [introElements, setIntroElements] = useState({ text: "", elements: [] });
  const [introAudio, setIntroAudio] = useState({ category: "audio", file: null, pack: "user" });
  /* eslint-enable no-unused-vars */

  useEffect(() => {
    let storyIntro = currentStory.story.intro;
    let storyIntroElements = currentStory.story.introElements;
    let storyIntroAudio = currentStory.story.introAudio;
    if (!storyIntro) {
      storyIntro = [{ text: "Story introductoray text" }];
    }
    if (!storyIntroElements) {
      storyIntroElements = { text: "", elements: [] };
    }
    if (!storyIntroAudio) {
      storyIntroAudio = { category: "audio", file: null, pack: "user" };
    }
    setIntro(storyIntro);
    setIntroElements(storyIntroElements);
    setIntroAudio(storyIntroAudio);
  }, [currentStory.story.intro, currentStory.story.introElements, currentStory.story.introAudio]);

  const backToMap = () => {
    dispatch("router/setParams", { storypage: "dashboard" });
  };

  const saveIntroClick = async () => {
    dispatch("ui/setLoading", true);
    await storyUpdate(currentStory.story.id, { intro, introElements, introAudio });
    dispatch("currentStory/updateStory", { intro, introElements });
    dispatch("ui/setLoading", false);
    dispatch("router/setDirty", false);
  };

  const addIntroBlock = (e) => {
    const newBlock = { text: "" };
    setIntro([...intro, newBlock]);
    dispatch("router/setDirty", true);
  };

  const removeIntroBlock = (index) => {
    const newBlocks = Array.from(intro);
    newBlocks.splice(index, 1);
    setIntro(newBlocks);
    dispatch("router/setDirty", true);
  };

  const checkMaxAndUpdate = (event, index) => {
    dispatch("router/setDirty", true);
    if (event.target.value.length <= maxCharacters) {
      const newIntro = [...intro];
      newIntro[index].text = event.target.value;
      setIntro(newIntro);
    }
  };

  const mentionsChange = (event, newValue, newPlainTextValue, mentions) => {
    let newMentions = mentions.map((m) => {
      return { id: m.id, display: m.display, type: getTypeFromId(currentStory.story, m.id) };
    });
    newMentions = newMentions.filter((obj, pos, arr) => arr.map((mapObj) => mapObj.id).indexOf(obj.id) === pos);
    setIntroElements({ text: newValue, elements: newMentions });
    dispatch("router/setDirty", true);
  };

  return (
    <React.Fragment>
      <div className={classes.fixedActions}>
        <Button type="submit" variant="contained" color="default" className={classes.margined} onClick={backToMap}>
          Return to Map
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          className={classes.margined}
          onClick={saveIntroClick}
        >
          Save Intro
        </Button>
      </div>
      <h1 className={classes.heading}>Story Intro</h1>

      <div className={classes.mentionsDiv}>
        <Typography component="p" className={classes.explainer}>
          Use an audio track to play during the intro period.
        </Typography>

        {introAudio && (
          <MediaAsset
            assetObject={introAudio}
            hideSelects={true}
            halfImage={true}
            color="elements"
            assetChanged={(asset) => setIntroAudio(asset)}
          />
        )}
      </div>

      <div className={classes.mentionsDiv}>
        <Typography component="p" className={classes.explainer}>
          Select the starting elements (locations, characters, topics and objectives) that all players will know at the
          beginning of the game.
        </Typography>
        <MentionBox
          value={introElements.text}
          onChange={mentionsChange}
          elements={{ locations: true, topics: true, characters: true, objectives: true, players: false }}
        />
        <Typography component="p" className={classes.explainer}>
          Starting common elements for all Players. Use * for an Objective, @ for a Location, # for a Topic, ^ for a
          Character.
        </Typography>
      </div>

      {intro &&
        intro.map((block, index) => (
          <div key={`indexBlock_${index}`} className={classes.introBlock}>
            <Avatar className={classes.elementsBg}>{index + 1}</Avatar>
            <div className={classes.inputDiv}>
              <Input
                fullWidth
                placeholder="...type an intro block"
                multiline={true}
                onChange={(e) => checkMaxAndUpdate(e, index)}
                value={block.text}
              />
              <div>{maxCharacters - (block.text ? block.text.length : 0)}</div>
            </div>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => removeIntroBlock(index)}
              className={classes.margined}
            >
              Remove
            </Button>
          </div>
        ))}

      <div>
        <Button
          size="small"
          variant="contained"
          color="secondary"
          onClick={addIntroBlock}
          className={classes.btnAddBlock}
        >
          ADD INTRO BLOCK
        </Button>
      </div>
    </React.Fragment>
  );
});
