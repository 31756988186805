const CacheStore = (store) => {
  store.on("@init", () => ({
    cache: {
      images: {}, // contains uid or assetPack1, etc
    },
  }));

  store.on("cache/setImages", ({ cache }, { target, arr }) => {
    cache.images[target] = arr;
    return { cache };
  });
};

export default CacheStore;
