import React, { useState } from "react";
import { useStoreon } from "storeon/react";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import MediaAsset from "modules/Main/_components/MediaAsset";
import { storiesCreate } from "services/dbService";
import { useInputValue } from "services/hooks";

const styles = (theme) => ({
  layout: {
    position: "relative",
    width: 800,
    maxWidth: "90%",
    display: "block", // Fix IE 11 issue.
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  explainer: {
    marginTop: 5,
    marginBottom: 25,
    fontSize: "1em",
    fontStyle: "italic",
  },
});

export default withStyles(styles)(({ classes }) => {
  /* eslint-disable no-unused-vars */
  const { dispatch, ui, router } = useStoreon("ui", "router");
  const [added, setAdded] = useState(false);

  const { ...description } = useInputValue("");
  const { ...difficulty } = useInputValue("");
  const { ...duration } = useInputValue("");
  const { ...name } = useInputValue("");
  const [map, setMap] = useState({ category: "maps", file: null, pack: "1" });
  const [cover, setCover] = useState({ category: "covers", file: null, pack: "1" });
  const [music, setMusic] = useState({ category: "audio", file: null, pack: "1" });
  /* eslint-enable no-unused-vars */

  const createFormSubmit = async (e) => {
    e.preventDefault();
    dispatch("ui/setLoading", true);
    await storiesCreate({
      name: name.value,
      description: description.value,
      difficulty: difficulty.value,
      duration: duration.value,
      map,
      cover,
      music,
    });
    dispatch("ui/setLoading", false);
    setAdded(true);
  };

  return (
    <React.Fragment>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">
            Create New Story
          </Typography>
          {!added && (
            <form className={classes.form} onSubmit={createFormSubmit}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel shrink={true} htmlFor="story-name">
                  Name
                </InputLabel>
                <Input {...name} name="story-name" autoComplete="story-name" autoFocus />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel shrink={true} htmlFor="story-description">
                  description
                </InputLabel>
                <Input {...description} name="story-description" autoComplete="story-description" />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel shrink={true} htmlFor="story-difficulty">
                  difficulty
                </InputLabel>
                <Input {...difficulty} name="story-difficulty" autoComplete="story-difficulty" />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel shrink={true} htmlFor="story-duration">
                  duration
                </InputLabel>
                <Input {...duration} name="story-duration" autoComplete="story-duration" />
              </FormControl>
              <Typography component="p" className={classes.explainer}>
                Main audio track. Used in game Lobby and Map (Location Select)
              </Typography>
              {music && (
                <MediaAsset
                  assetObject={music}
                  hideSelects={true}
                  halfImage={true}
                  color="elements"
                  assetChanged={(e) => setMusic(e)}
                />
              )}
              <Typography component="p" className={classes.explainer}>
                Story Map
              </Typography>
              {map && (
                <MediaAsset
                  assetObject={map}
                  hideSelects={true}
                  halfImage={true}
                  color="elements"
                  assetChanged={(e) => setMap(e)}
                />
              )}
              <Typography component="p" className={classes.explainer}>
                Story Cover Image
              </Typography>
              {cover && (
                <MediaAsset
                  assetObject={cover}
                  hideSelects={true}
                  halfImage={true}
                  color="elements"
                  assetChanged={(e) => setCover(e)}
                />
              )}
              <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                Create
              </Button>
            </form>
          )}
          {added && (
            <div>
              <Typography component="h5" variant="h5">
                New story Added!
              </Typography>
            </div>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => dispatch("router/routeTo", { page: "storiesList", id: 0 })}
          >
            Back to List
          </Button>
        </Paper>
      </main>
    </React.Fragment>
  );
});
