import React, { useState, useEffect, useCallback } from "react";
import { useStoreon } from "storeon/react";

import { Network } from "vis-network";
import "vis-network/dist/dist/vis-network.min.css";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { getAssetUrl } from "services/utilities";
import { colors } from "services/theme";

const styles = {
  root: {
    display: "flex",
    flexWrap: "wrap",
    height: "100%",
  },
  margined: {
    margin: "10px",
  },
  networkRoot: {
    width: "100%",
    height: "100%",
    flexGrow: 1,
  },
};

export default withStyles(styles)(({ classes }) => {
  /* eslint-disable no-unused-vars */
  const { dispatch, currentStory } = useStoreon("currentStory");
  const [nodes, setNodes] = useState(null);
  const [edges, setEdges] = useState(null);
  const [network, setNetwork] = useState(null);
  /* eslint-enable no-unused-vars */

  // TODO: dont add level, but have an object to save each id's level(s)
  // to avoid loops, check if inArray that level already. If not add.
  // Then loop through nodes and add level the lowest from the array in the object[node.id]
  const setNodeLevel = useCallback((level, node, nodesData, levelsAll, ignoreIfSet) => {
    if (!levelsAll[node.id]) {
      levelsAll[node.id] = [];
    }
    if (node && !levelsAll[node.id].includes(level)) {
      if (!ignoreIfSet) {
        levelsAll[node.id].push(level);
      } else {
        if (levelsAll[node.id].length === 0) {
          levelsAll[node.id].push(level);
        }
      }
      node.clues
        .filter((c) => c.type === "locations")
        .forEach((locNew) => {
          const nodeNew = nodesData.find((n) => n.id === locNew.id);
          if (nodeNew.id !== node.id) {
            setNodeLevel(level + 1, nodeNew, nodesData, levelsAll, ignoreIfSet);
          }
        });
    }
  }, []);

  useEffect(() => {
    let nodesData = [];
    const edgesData = [];

    /* INTRO */
    nodesData = [
      {
        id: currentStory.story.id,
        label: "Intro",
        title: "Intro",
        image: getAssetUrl(currentStory.story.cover),
        color: {
          border: colors.elements.main,
          background: colors.elements.main,
        },
        clues: currentStory.story.introElements.elements,
        elementType: "intro",
      },
    ];

    /* Players */
    nodesData = nodesData.concat(
      currentStory.story.players.map((player) => {
        return {
          id: player.id,
          label: player.name,
          title: player.name,
          image: getAssetUrl(player.asset),
          color: {
            border: colors.players.main,
            background: colors.players.main,
          },
          clues: player.mentions,
          elementType: "player",
        };
      })
    );

    /* Event Choices */
    currentStory.story.events.forEach((event) => {
      let eventClues = [];
      event.blocks.forEach((block) => {
        eventClues = eventClues.concat(block.clues.reduce((arr, clue) => (arr = arr.concat(clue.elements)), []));
      });

      event.choices.forEach((choice) => {
        let clues = eventClues;
        choice.blocks.forEach((block) => {
          clues = clues.concat(block.clues.reduce((arr, clue) => (arr = arr.concat(clue.elements)), []));
        });
        nodesData.push({
          id: `${event.id}_${choice.buttonTxt}`,
          label: `${event.name} - ${choice.buttonTxt}`,
          title: `${event.name} - ${choice.buttonTxt}`,
          image: getAssetUrl(event.asset),
          color: {
            border: colors.events.main,
            background: colors.events.main,
          },
          clues,
          elementType: "event",
        });
      });
    });

    /* Locations */
    nodesData = nodesData.concat(
      currentStory.story.locations.map((loc) => {
        let clues = [];
        loc.blocks.forEach((block) => {
          clues = clues.concat(block.clues.reduce((arr, clue) => (arr = arr.concat(clue.elements)), []));
        });
        return {
          id: loc.id,
          label: loc.name,
          title: loc.name,
          image: getAssetUrl(loc.asset),
          clues,
          elementType: "location",
        };
      })
    );

    const levelsAll = {};

    const isInt = (value) => {
      if (isNaN(value)) {
        return false;
      }
      var x = parseFloat(value);
      return (x | 0) === x;
    };

    nodesData.forEach((node) => {
      if (node.elementType === "player" || node.elementType === "intro") {
        setNodeLevel(1, node, nodesData, levelsAll, false);
      }
      if (node.elementType === "event") {
        let startLevel = 1;
        const minLevelofLocations = Math.min(
          ...node.clues
            .filter((c) => c.type === "locations")
            .map((c) => (isInt(levelsAll[c.id]) ? levelsAll[c.id] : -1))
            .filter((a) => a !== -1)
        );
        if (isInt(minLevelofLocations)) {
          startLevel = minLevelofLocations - 1;
        }
        setNodeLevel(startLevel, node, nodesData, levelsAll, true);
      }
    });

    nodesData.forEach((node) => {
      if (levelsAll[node.id]) {
        node.level = Math.min(...levelsAll[node.id]);
      }
      if (node.elementType === "player" || node.elementType === "intro") {
        node.level = 1;
      }
      if (!node.level) {
        node.level = 2;
      }
      node.clues
        .filter((n) => n.type === "locations")
        .forEach((location) => {
          if (node.id !== location.id) {
            edgesData.push({ from: node.id, to: location.id });
          }
        });
    });

    setEdges(edgesData);
    setNodes(nodesData);

    const options = {
      // nodes
      nodes: {
        shape: "circularImage",
        borderWidth: 7,
        borderWidthSelected: 9,
        size: 45,
        color: {
          border: colors.locations.main,
          background: colors.locations.main,
          highlight: {
            border: "#2B7CE9",
            background: "#D2E5FF",
          },
        },
        font: {
          color: colors.locations.secondary,
          size: 14,
        },
        widthConstraint: {
          maximum: 140,
        },
      },
      // edges
      edges: {
        arrows: {
          //middle: { enabled: true, scaleFactor: 1, type: 'arrow' },
          to: { enabled: true, scaleFactor: 1.2, type: "arrow" },
        },
        arrowStrikethrough: true,
        color: {
          color: colors.locations.main,
          highlight: "#2B7CE9",
          hover: "#D2E5FF",
        },
        smooth: {
          enabled: true,
          type: "dynamic",
          roundness: 0.5,
          forceDirection: "horizontal",
        },
        selfReference: { size: 25, angle: Math.PI / 4 },
      },
      // interaction
      interaction: {
        dragNodes: false,
        hover: true,
        hoverConnectedEdges: true,
        tooltipDelay: 0,
      },
      // layout
      layout: {
        hierarchical: {
          levelSeparation: 240,
          parentCentralization: true,
          direction: "UD", //LR UD
          sortMethod: "directed",
        },
      },
      // physics
      physics: {
        enabled: true,
        solver: "hierarchicalRepulsion",
        hierarchicalRepulsion: {
          nodeDistance: 230,
        },
      },
    };
    const networkData = new Network(
      document.getElementById("mynetwork"),
      { nodes: nodesData, edges: edgesData },
      options
    );
    setNetwork(networkData);

    networkData.on("select", function (params) {
      console.log(params);
    });
  }, [
    setNodeLevel,
    currentStory.story.id,
    currentStory.story.cover,
    currentStory.story.introElements.elements,
    currentStory.story.events,
    currentStory.story.locations,
    currentStory.story.players,
    currentStory.story.startingEventID,
  ]);

  return (
    <Typography component="div" className={classes.root}>
      <div id="mynetwork" className={classes.networkRoot} />
    </Typography>
  );
});
