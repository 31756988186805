import React from "react";
import { useStoreon } from "storeon/react";

import { withStyles } from "@material-ui/core/styles";
import { MentionsInput, Mention } from "react-mentions";

import { bgColors, mentionStyles } from "services/theme";

const styles = (theme) => ({
  ...bgColors,
});

export default withStyles(styles)(({ classes, value, onChange, elements }) => {
  /* eslint-disable no-unused-vars */
  const { dispatch, currentStory } = useStoreon("currentStory");
  /* eslint-enable no-unused-vars */

  return (
    <React.Fragment>
      {elements && (
        <MentionsInput value={value} onChange={onChange} style={mentionStyles}>
          <Mention
            trigger="@"
            data={
              elements.locations
                ? currentStory.story.locations.map((el) => {
                    return { id: el.id, display: el.name };
                  })
                : []
            }
            style={bgColors.locationsBg}
            appendSpaceOnAdd={true}
            markup="@[__display__](__id__)"
          />
          <Mention
            trigger="#"
            data={
              elements.topics
                ? currentStory.story.topics.map((el) => {
                    return { id: el.id, display: el.name };
                  })
                : []
            }
            style={bgColors.topicsBg}
            appendSpaceOnAdd={true}
            markup="#[__display__](__id__)"
          />
          <Mention
            trigger="^"
            data={
              elements.characters
                ? currentStory.story.characters.map((el) => {
                    return { id: el.id, display: el.name };
                  })
                : []
            }
            style={bgColors.charactersBg}
            appendSpaceOnAdd={true}
            markup="^[__display__](__id__)"
          />
          <Mention
            trigger="*"
            data={
              elements.objectives
                ? currentStory.story.objectives.map((el) => {
                    return { id: el.id, display: el.name };
                  })
                : []
            }
            style={bgColors.objectivesBg}
            appendSpaceOnAdd={true}
            markup="*[__display__](__id__)"
          />
          <Mention
            trigger="%"
            data={
              elements.players
                ? currentStory.story.players.map((el) => {
                    return { id: el.id, display: el.name };
                  })
                : []
            }
            style={bgColors.playersBg}
            appendSpaceOnAdd={true}
            markup="%[__display__](__id__)"
          />
        </MentionsInput>
      )}
    </React.Fragment>
  );
});
