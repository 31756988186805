import {
  Firebase,
  FirebaseAuth,
  // FirebaseRealtime,
  dbUsers,
  dbStories,
  dbAssetPacks,
  dbPublished,
  FirebaseStorage,
} from "services/firebase";

import { asyncForEach } from "services/utilities";

const AuthCheck = () => {
  return new Promise((resolve, reject) => {
    FirebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        resolve(user);
      } else {
        resolve(false);
      }
    });
  });
};

const AuthSignin = (user) => {
  return new Promise((resolve, reject) => {
    if (user) {
      FirebaseAuth.signInWithEmailAndPassword(user.email, user.password)
        .then((usr) => {
          resolve(usr);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      reject({ message: "Please enter your email and password" });
    }
  });
};

const AuthSignup = (user) => {
  return new Promise((resolve, reject) => {
    if (user) {
      FirebaseAuth.createUserWithEmailAndPassword(user.email, user.password)
        .then((usr) => {
          const uid = usr.user.uid;
          const now = new Date();
          dbUsers
            .doc(uid)
            .set({
              userID: uid,
              email: user.email,
              createdAt: now,
              updatedAt: now,
            })
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      reject("No user");
    }
  });
};

const AuthLogout = () => {
  FirebaseAuth.signOut()
    .then(() => {
      window.location.reload();
    })
    .catch((error) => {
      console.error(error);
    });
};

const getUser = () => {
  return new Promise((resolve, reject) => {
    const uid = FirebaseAuth.currentUser.uid;
    dbUsers
      .doc(uid)
      .get()
      .then((profile) => {
        const currentUser = profile.data();
        resolve(currentUser);
      })
      .catch((error) => {
        reject(error);
        console.error(error);
      });
  });
};

const getUserStories = () => {
  return new Promise((resolve, reject) => {
    const uid = FirebaseAuth.currentUser.uid;
    dbStories
      .where("uid", "==", uid)
      .get()
      .then((stories) => {
        const arr = [];
        if (!stories.empty) {
          stories.docs.forEach((story) => {
            const el = story.data();
            el.id = story.id;
            arr.push(el);
          });
        }
        resolve(arr);
      })
      .catch((error) => {
        reject(error);
        console.error(error);
      });
  });
};

// @TODO: cache fetched images. Add uploaded images to cache. Add refresh button to re-fetch fresh list.
// @TODO: easy create asset packs: make new user with id assetPack1, etc, then upload in editor.
const getAssetPack = (num = null) => {
  const pack = num ? `assetPack${num}` : FirebaseAuth.currentUser.uid;
  return new Promise((resolve, reject) => {
    dbAssetPacks
      .doc(pack)
      .get()
      .then(async (assetPack) => {
        const images = assetPack.data().images;
        if (!images) {
          resolve({
            images: [],
          });
          return true;
        }
        resolve({ images });
      })
      .catch((error) => {
        reject(error);
        console.error(error);
      });
  });
};

const addImageToAssetPack = (newImage) => {
  const uid = FirebaseAuth.currentUser.uid;
  return new Promise((resolve, reject) => {
    dbAssetPacks
      .doc(uid)
      .update({ images: Firebase.firestore.FieldValue.arrayUnion(newImage) })
      .then((doc) => {
        resolve(doc);
      })
      .catch((error) => {
        reject(error);
        console.error(error);
      });
  });
};

// @TODO: add firebase rules to storage + db to allow only self edit
const deleteImageFromAssetPack = (image) => {
  const uid = FirebaseAuth.currentUser.uid;
  return new Promise((resolve, reject) => {
    dbAssetPacks
      .doc(uid)
      .get()
      .then(async (doc) => {
        const images = doc.data().images.filter((im) => im.id !== image.id);
        await doc.ref.update({ images });

        FirebaseStorage.init(uid);
        // remove from storage
        const storageRef = FirebaseStorage.images.child(image.filename);
        storageRef.delete(); // don't await to save time. @TODO: check errors?
        // remove from storage
        resolve(image);
      })
      .catch((error) => {
        reject(error);
        console.error(error);
      });
  });
};

const storiesCreate = (data) => {
  return new Promise((resolve, reject) => {
    const uid = FirebaseAuth.currentUser.uid;
    const now = new Date();
    dbStories
      .add({ ...data, uid, createdAt: now, updatedAt: now })
      .then(async (story) => {
        resolve(story);
      })
      .catch((error) => {
        reject(error);
        console.error(error);
      });
  });
};

const storyDelete = (storyID) => {
  // @TODO: get and delete versions as well
  return new Promise((resolve, reject) => {
    const del1 = new Promise((res, rej) => {
      dbStories
        .doc(storyID)
        .collection("characters")
        .get()
        .then((docs) => {
          docs.forEach((d) => d.ref.delete());
          res(docs);
        })
        .catch((e) => rej(e));
    });
    const del2 = new Promise((res, rej) => {
      dbStories
        .doc(storyID)
        .collection("topics")
        .get()
        .then((docs) => {
          docs.forEach((d) => d.ref.delete());
          res(docs);
        })
        .catch((e) => rej(e));
    });
    const del3 = new Promise((res, rej) => {
      dbStories
        .doc(storyID)
        .collection("objectives")
        .get()
        .then((docs) => {
          docs.forEach((d) => d.ref.delete());
          res(docs);
        })
        .catch((e) => rej(e));
    });
    const del4 = new Promise((res, rej) => {
      dbStories
        .doc(storyID)
        .collection("players")
        .get()
        .then((docs) => {
          docs.forEach((d) => d.ref.delete());
          res(docs);
        })
        .catch((e) => rej(e));
    });
    const del5 = new Promise((res, rej) => {
      dbStories
        .doc(storyID)
        .collection("locations")
        .get()
        .then((docs) => {
          docs.forEach((d) => d.ref.delete());
          res(docs);
        })
        .catch((e) => rej(e));
    });
    const del6 = new Promise((res, rej) => {
      dbStories
        .doc(storyID)
        .collection("events")
        .get()
        .then((docs) => {
          docs.forEach((d) => d.ref.delete());
          res(docs);
        })
        .catch((e) => rej(e));
    });
    Promise.all([del1, del2, del3, del4, del5, del6])
      .then((res) => {
        dbStories
          .doc(storyID)
          .delete()
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
            console.error(error);
          });
      })
      .catch((error) => {
        reject(error);
        console.error(error);
      });
  });
};

const createNewStoryVersion = (storyID) => {
  const now = new Date();
  return new Promise(async (resolve, reject) => {
    const story = await dbStories.doc(storyID).get();
    const storyData = story.data();

    let versionNumber = storyData.versionMax || 2;
    let baseID = storyID;
    if (storyData.version) {
      baseID = storyData.version.baseID;
      const baseStory = await dbStories.doc(baseID).get();
      const baseStoryData = baseStory.data();
      versionNumber = baseStoryData.versionMax + 1;
    }
    const newID = `${baseID}${versionNumber}`;

    dbStories
      .doc(newID)
      .set({
        ...storyData,
        version: {
          number: versionNumber,
          baseID,
        },
        createdAt: now,
        updatedAt: now,
      })
      .then(async (added) => {
        // Also add to base List of versions
        await dbStories.doc(baseID).update({
          versionMax: versionNumber,
          versionList: Firebase.firestore.FieldValue.arrayUnion({
            id: newID,
            number: versionNumber,
          }),
          updatedAt: now,
        });

        const newStory = await dbStories.doc(newID).get();

        const characters = await dbStories.doc(storyID).collection("characters").get();
        await asyncForEach(characters.docs, async (d) => {
          await newStory.ref.collection("characters").doc(d.id).set(d.data());
        });
        const events = await dbStories.doc(storyID).collection("events").get();
        await asyncForEach(events.docs, async (d) => {
          await newStory.ref.collection("events").doc(d.id).set(d.data());
        });
        const locations = await dbStories.doc(storyID).collection("locations").get();
        await asyncForEach(locations.docs, async (d) => {
          await newStory.ref.collection("locations").doc(d.id).set(d.data());
        });
        const objectives = await dbStories.doc(storyID).collection("objectives").get();
        await asyncForEach(objectives.docs, async (d) => {
          await newStory.ref.collection("objectives").doc(d.id).set(d.data());
        });
        const players = await dbStories.doc(storyID).collection("players").get();
        await asyncForEach(players.docs, async (d) => {
          await newStory.ref.collection("players").doc(d.id).set(d.data());
        });
        const topics = await dbStories.doc(storyID).collection("topics").get();
        await asyncForEach(topics.docs, async (d) => {
          await newStory.ref.collection("topics").doc(d.id).set(d.data());
        });

        resolve(newID);
      })
      .catch((error) => {
        reject(error);
        console.error(error);
      });
  });
};

const storyUpdate = (storyID, data) => {
  return new Promise((resolve, reject) => {
    dbStories
      .doc(storyID)
      .update(data)
      .then((res) => {
        resolve(true);
      })
      .catch((error) => {
        reject(error);
        console.error(error);
      });
  });
};

const getFullStory = (id) => {
  return new Promise((resolve, reject) => {
    dbStories
      .doc(id)
      .get()
      .then(async (story) => {
        // Get and populate sub-collections
        const storyData = story.data();
        const charactersList = await dbStories.doc(id).collection("characters").orderBy("createdAt").get();
        const characters = charactersList.docs.map((el) => {
          return { ...el.data(), id: el.id };
        });

        const topicsList = await dbStories.doc(id).collection("topics").orderBy("createdAt").get();
        const topics = topicsList.docs.map((el) => {
          return { ...el.data(), id: el.id };
        });

        const objectivesList = await dbStories.doc(id).collection("objectives").orderBy("createdAt").get();
        const objectives = objectivesList.docs.map((el) => {
          return { ...el.data(), id: el.id };
        });

        const playersList = await dbStories.doc(id).collection("players").orderBy("createdAt").get();
        const players = playersList.docs.map((el) => {
          return { ...el.data(), id: el.id };
        });

        const locationsList = await dbStories.doc(id).collection("locations").orderBy("createdAt").get();
        const locations = locationsList.docs.map((el) => {
          return { ...el.data(), id: el.id };
        });

        const eventsList = await dbStories.doc(id).collection("events").orderBy("createdAt").get();
        const events = eventsList.docs.map((el) => {
          return { ...el.data(), id: el.id };
        });
        // events.sort((a, b) => (a.id === storyData.startingEventID ? -1 : b.id === storyData.startingEventID ? 1 : 0));

        const fullStory = {
          ...storyData,
          id: story.id,
          characters,
          topics,
          objectives,
          players,
          locations,
          events,
        };
        resolve(fullStory);
      })
      .catch((error) => {
        reject(error);
        console.error(error);
      });
  });
};

const addElement = (story, type, element) => {
  const now = new Date();
  return new Promise((resolve, reject) => {
    if (story[type].find((e) => e.name === element.name)) {
      reject("Cannot use a name that already exists.");
      return false;
    }
    dbStories
      .doc(story.id)
      .collection(type)
      .add({ ...element, createdAt: now, updatedAt: now })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
        console.error(error);
      });
  });
};

const saveElement = (story, type, element, oldName) => {
  return new Promise((resolve, reject) => {
    if (story[type].find((e) => e.name === element.name && e.id !== element.id)) {
      reject("Cannot use a name that already exists.");
      return false;
    }
    element.updatedAt = Firebase.firestore.FieldValue.serverTimestamp();
    dbStories
      .doc(story.id)
      .collection(type)
      .doc(element.id)
      .update(element)
      .then((res) => {
        resolve(true);
      })
      .catch((error) => {
        reject(error);
        console.error(error);
      });
  });
};

const deleteElement = (storyID, type, el) => {
  return new Promise((resolve, reject) => {
    dbStories
      .doc(storyID)
      .collection(type)
      .doc(el.id)
      .delete()
      .then((res) => {
        resolve(true);
      })
      .catch((error) => {
        reject(error);
        console.error(error);
      });
  });
};

const updateMentions = (story) => {
  return new Promise((resolve, reject) => {
    const p1 = new Promise((res, rej) => {
      dbStories
        .doc(story.id)
        .collection("locations")
        .get()
        .then((docs) => {
          docs.forEach((d) => d.ref.update(story.locations.find((e) => e.id === d.id)));
          res(true);
        })
        .catch((error) => {
          rej(error);
          console.error(error);
        });
    });
    const p2 = new Promise((res, rej) => {
      dbStories
        .doc(story.id)
        .collection("events")
        .get()
        .then((docs) => {
          docs.forEach((d) => d.ref.update(story.events.find((e) => e.id === d.id)));
          res(true);
        })
        .catch((error) => {
          rej(error);
          console.error(error);
        });
    });
    Promise.all([p1, p2])
      .then((res) => resolve(res))
      .catch((error) => {
        reject(error);
        console.error(error);
      });
  });
};

const saveStoryPublish = (story) => {
  const now = new Date();
  const json = JSON.stringify(story);
  return new Promise((resolve, reject) => {
    dbUsers
      .doc(story.uid)
      .get()
      .then((usr) => {
        const data = {
          json,
          createdAt: now.toString(),
          updatedAt: now.toString(),
          version: 1,
          name: story.name,
          description: story.description,
          meta: {
            difficulty: story.difficulty,
            duration: story.duration,
          },
          user: usr.data(),
          players: {
            min: story.players.filter((p) => p.required === true).length,
            max: story.players.length,
          },
        };

        dbPublished
          .doc(story.id)
          .get()
          .then((doc) => {
            if (!doc.exists) {
              dbPublished
                .doc(story.id)
                .set(data)
                .then((res) => {
                  resolve(res);
                });
            } else {
              delete data.createdAt;
              dbPublished
                .doc(story.id)
                .update(data)
                .then((res) => {
                  resolve(res);
                });
            }
          })
          .catch((error) => {
            reject(error);
            console.error(error);
          });
      })
      .catch((error) => {
        reject(error);
        console.error(error);
      });
  });
};

const removeStoryPublish = (id) => {
  return new Promise((resolve, reject) => {
    dbPublished
      .doc(id)
      .delete()
      .then((res) => {
        resolve(true);
      })
      .catch((error) => {
        reject(error);
        console.error(error);
      });
  });
};

const getPuzzles = () => {
  return [
    {
      id: "ImageShuffle",
      name: "Image Shuffle",
      options: {
        image: { type: "media", value: "" },
        level: { type: "number", value: 4 },
      },
    },
    {
      id: "SimplePassword",
      name: "Require a simple text password",
      options: {
        text: { type: "text", value: "password" },
      },
    },
  ];
};

export {
  AuthCheck,
  AuthSignin,
  AuthSignup,
  AuthLogout,
  getUser,
  getUserStories,
  getAssetPack,
  addImageToAssetPack,
  storiesCreate,
  storyDelete,
  storyUpdate,
  getFullStory,
  addElement,
  saveElement,
  deleteElement,
  updateMentions,
  saveStoryPublish,
  removeStoryPublish,
  createNewStoryVersion,
  deleteImageFromAssetPack,
  getPuzzles,
};
