import React, { useEffect } from "react";

import { useStoreon } from "storeon/react";
import { getUserStories } from "services/dbService";

import StoryCard from "./components/StoryCard";

const StoriesList = () => {
  /* eslint-disable no-unused-vars */
  const { dispatch, ui, stories } = useStoreon("ui", "stories");
  /* eslint-enable no-unused-vars */

  useEffect(() => {
    dispatch("ui/setLoading", true);
    getUserStories().then((storiesAll) => {
      dispatch("ui/setLoading", false);
      dispatch("stories/setStories", storiesAll);
    });
  }, [dispatch]); // []  = run only once

  return (
    <div style={{ padding: 20, display: "flex", flexWrap: "wrap" }}>
      <StoryCard story={null} />
      {stories.stories && stories.stories.map((story) => <StoryCard key={story.id} story={story} />)}
    </div>
  );
};

export default StoriesList;
